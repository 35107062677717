
:root {
  --theme-color1: #022d0c; /* Dark Green*/
  --theme-color2: #93bb57; /* Parrot*/
  --theme-color3: #d1af4b; /* Orange*/
  --theme-color4: #b3c2c1; /* gray*/
  --bg-theme-color1: var(--theme-color1);
  --bg-theme-color2: var(--theme-color2);
  --bg-theme-color3: var(--theme-color3);
  --bg-theme-color4: var(--theme-color4);
  --border-theme-color1: var(--theme-color1);
  --border-theme-color2: var(--theme-color2);
  --border-theme-color3: var(--theme-color3);
  --border-theme-color4: var(--theme-color4);
  --text-color-bg-theme-color1: #fff;
  --text-color-bg-theme-color2: #fff;
  --text-color-bg-theme-color3: #fff;
  --text-color-bg-theme-color4: #fff;
  --text-color: #819291;
  --headings-color: var(--theme-color1);
  --link-color: #352F27;
  --link-hover-color: #5B8C51;
  --text-font: "Manrope", sans-serif;
  --title-font: "Manrope", sans-serif;
  --body-font-size: 16px;
  --body-font-weight: 400;
  --body-font-weight-bold: 400;
  --body-line-height: 1.9;
  --line-height-heading: 1.4;
  --line-height-heading-h1: 1.1;
  --line-height-heading-small: 1.4;
  --h1-font-size: 90px;
  --h2-font-size: 42px;
  --h3-font-size: 30px;
  --h4-font-size: 24px;
  --h5-font-size: 20px;
  --h6-font-size: 18px;
  --h1-font-weight: 800;
  --h2-font-weight: 800;
  --h3-font-weight: 800;
  --h4-font-weight: 800;
  --h5-font-weight: 800;
  --h6-font-weight: 800;
  --sec-title-color: var(--headings-color);
  --sec-title-font-size: 50px;
  --sec-title-font-family: var(--title-font);
  --sec-title-font-weight: var(--h2-font-weight);
  --sec-title-subtitle-color: #819291;
  --sec-title-subtitle-font-size: 16px;
  --sec-title-subtitle-font-family: var(--text-font);
  --sec-title-subtitle-font-weight: 700;
  --theme-light-background: #f8f6f1;
  --theme-light-background-text-color: var(--headings-color);
  --theme-black: #0e2207;
  --container-width: 1200px;
  --small-container-width: 1000px;
  --large-container-width: 1550px;
  --container-pt: 120px;
  --container-pb: 120px;
}

/*** 

====================================================================
Fonts
====================================================================

***/
/*** 

====================================================================
Reset
====================================================================

***/
* {
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none;
  font-size: 100%;
}

::-webkit-input-placeholder {
  color: #819291;
}

::-moz-input-placeholder {
  color: #819291;
}

::-ms-input-placeholder {
  color: #819291;
}


textarea {
  overflow: hidden;
  resize: none;
}

button {
  outline: none !important;
  cursor: pointer;
}

p, .text {
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
  color: var(--theme-color1);
  margin: 0;
}

.colored {
  color: var(--theme-color1);
}

.auto-container {
  position: static;
  max-width: 1200px;
  padding: 0px 15px;
  margin: 0 auto;
}

.small-container {
  position: static;
  max-width: 1000px;
  padding: 0px 15px;
  margin: 0 auto;
}

.large-container {
  position: static;
  max-width: 1610px;
  padding: 0px 15px;
  margin: 0 auto;
}

.page-wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-width: 300px;
  overflow: hidden;
  z-index: 99;
  background-color: #ffffff;
}

ul, li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

/*=== List Style One ===*/
.list-style-one {
  position: relative;
}

.list-style-one li {
  position: relative;
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  padding-left: 25px;
  color: var(--bg-theme-color1);
  margin-bottom: 10px;
}
.list-style-one li:before {
  position: absolute;
  left: 0;
  top: 1px;
  font-size: 14px;
  color: var(--bg-theme-color3);
  line-height: 26px;
  content: "\f058";
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
}

/*Social Icon One*/
.social-icon-one {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.social-icon-one li {
  position: relative;
  margin-right: 10px;
}
.social-icon-one li:last-child {
  margin-right: 0;
}
.social-icon-one li a {
  position: relative;
  display: block;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 15px;
  color: #ffffff;
  background: var(--theme-color1);
  border-radius: 50%;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.social-icon-one li a:hover {
  color: #ffffff;
  background: var(--theme-color2);
}

/*Social Icon Two*/
.social-icon-two {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.social-icon-two li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
}
.social-icon-two li a {
  position: relative;
  display: block;
  line-height: 30px;
  text-align: center;
  font-size: 15px;
  color: #ffffff;
  border-radius: 50%;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.social-icon-two li a:hover {
  color: var(--theme-color3);
}

/*Social Icon Three*/
.social-icon-three {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.social-icon-three li {
  position: relative;
  margin-left: 20px;
}

.social-icon-three li a {
  position: relative;
  display: block;
  line-height: 40px;
  text-align: center;
  font-size: 15px;
  color: #2f3529;
  border-radius: 50%;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.social-icon-three li a:hover {
  color: var(--theme-color2);
}

.theme_color {
  color: #25262c;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.preloader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #ffffff;
}

.preloader:after {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100px;
  width: 100px;
  margin-left: -50px;
  margin-top: -60px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 64px;
  content: "";
}

.preloader:before {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  margin-top: 30px;
  color: var(--theme-color1);
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 1px;
  content: "Loading";
  text-transform: uppercase;
  -webkit-transition: none;
  transition: none;
}

img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.tabs-box {
  position: relative;
}

.tabs-box .tab {
  display: none;
}

.tabs-box .active-tab {
  display: block;
}

/*
 * typography.scss
 * -----------------------------------------------
*/
::-moz-selection {
  background: #fff;
  color: #fff;
  text-shadow: none;
}
::selection {
  background: #fff;
  color: #fff;
  text-shadow: none;
}

::-moz-selection {
  background: #333; /* Firefox */
  color: #fff;
  text-shadow: none;
}

::-webkit-selection {
  background: #333; /* Safari */
  color: #fff;
  text-shadow: none;
}

:active,
:focus {
  outline: none !important;
}

body {
  /* background-color: #fff; */
  background: url(../../assets/images/mainbg.jpeg);
  background-attachment: fixed;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
  counter-reset: my-sec-counter;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
}

body, p, .text {
  color: var(--text-color);
  font-size: var(--body-font-size);
  font-family: var(--text-font);
  font-weight: var(--body-font-weight);
  line-height: var(--body-line-height);
}

/* -------- Headings ---------- */
h1, h2, h3, h4, h5, h6 {
  color: var(--headings-color);
  font-family: var(--title-font);
  position: relative;
  line-height: 1.2em;
  letter-spacing: -0.03em;
}
h1 small,
h1 .small, h2 small,
h2 .small, h3 small,
h3 .small, h4 small,
h4 .small, h5 small,
h5 .small, h6 small,
h6 .small {
  font-weight: normal;
  line-height: 1;
  color: var(--headings-color);
}
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  color: inherit;
  font-weight: inherit;
}

h1, h2, h3 {
  margin-bottom: 1rem;
  margin-top: 0.75rem;
  letter-spacing: -0.03em;
}

h4, h5, h6 {
  margin-bottom: 1rem;
  margin-top: 0.75rem;
}

h1 {
  font-size: var(--h1-font-size);
  font-weight: var(--h1-font-weight);
  line-height: var(--line-height-heading-h1);
}

h2 {
  font-size: var(--h2-font-size);
  font-weight: var(--h2-font-weight);
  line-height: var(--line-height-heading);
}

h3 {
  font-size: var(--h3-font-size);
  font-weight: var(--h3-font-weight);
  line-height: var(--line-height-heading);
}

h4 {
  font-size: var(--h4-font-size);
  font-weight: var(--h4-font-weight);
  line-height: var(--line-height-heading-small);
}

h5 {
  font-size: var(--h5-font-size);
  font-weight: var(--h5-font-weight);
  line-height: var(--line-height-heading-small);
}

h6 {
  font-size: var(--h6-font-size);
  font-weight: var(--h6-font-weight);
  line-height: var(--line-height-heading-small);
}

/* -------- Body Text ---------- */
table p {
  margin-bottom: 0;
}

p {
  margin-bottom: 20px;
}
/* -------- other ---------- */
a {
  color: var(--link-color);
  text-decoration: none;
  font-weight: var(--body-font-weight);
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
a:hover, a:focus {
  color: var(--theme-color1);
  text-decoration: none;
  outline: none;
}
a b, a strong {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
a img {
  border: none;
}

pre,
ul,
ol,
dl,
dd,
blockquote,
address,
table,
fieldset {
  margin-bottom: 10px;
}

ol, ul {
  list-style-position: inside;
  margin: 0;
  padding: 0;
}

b, strong {
  color: #333;
  font-weight: var(--body-font-weight-bold);
}

iframe {
  border: none !important;
}

/*
 * container.scss
 * -----------------------------------------------
*/
.container .container {
  width: 100%;
}

.container .container,
.container .container-fluid,
.container-fluid .container,
.container-fluid .container-fluid {
  padding-left: 0;
  padding-right: 0;
}

section > .container, section > .container-fluid {
  padding-top: var(--container-pt);
  padding-bottom: var(--container-pt);
}

@media (min-width: 1400px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: var(--container-width);
  }
}
/*=== Default Form ===*/
.form-control, .input-text {
  height: calc(2.25rem + 27px);
  padding: 14px 30px;
  outline: 0;
  background-color: #f4f5f8;
  border: 1px solid #f4f5f8;
  color: #686a6f;
  font-size: 0.9rem;
  width: 100%;
}
.form-control::-webkit-input-placeholder, .input-text::-webkit-input-placeholder {
  color: #686a6f;
  opacity: 1;
}
.form-control::-moz-placeholder, .input-text::-moz-placeholder {
  color: #686a6f;
  opacity: 1;
}
.form-control:-ms-input-placeholder, .input-text:-ms-input-placeholder {
  color: #686a6f;
  opacity: 1;
}
.form-control::-ms-input-placeholder, .input-text::-ms-input-placeholder {
  color: #686a6f;
  opacity: 1;
}
.form-control::placeholder, .input-text::placeholder {
  color: #686a6f;
  opacity: 1;
}
.form-control:-ms-input-placeholder, .input-text:-ms-input-placeholder {
  color: #686a6f;
}
.form-control::-ms-input-placeholder, .input-text::-ms-input-placeholder {
  color: #686a6f;
}
.form-control:focus, .input-text:focus {
  border-color: var(--theme-color1);
  -webkit-box-shadow: none;
          box-shadow: none;
}

textarea.form-control {
  height: auto;
  padding-top: 15px;
  padding-bottom: 15px;
}

.theme-btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  white-space: nowrap;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

/*Btn Style One*/
.btn-style-one {
  position: relative;
  font-size: 12px;
  line-height: 30px;
  color: #ffffff;
  padding: 10px 60px;
  font-weight: 800;
  background: var(--bg-theme-color2);
  letter-spacing: 0.05em;
  border-radius: 50px;
  overflow: hidden;
  text-transform: uppercase;
}
.btn-style-one:hover {
  background-color: var(--bg-theme-color3);
  color: var(--theme-color1);
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
          box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

/*Btn Style two*/
.btn-style-two {
  position: relative;
  font-size: 14px;
  line-height: 30px;
  color: #ffffff;
  padding: 12px 60px;
  font-weight: 600;
  background: var(--bg-theme-color3);
  letter-spacing: 0.05em;
  border-radius: 50px;
  overflow: hidden;
  text-transform: uppercase;
}
.btn-style-two:hover {
  background-color: var(--bg-theme-color2);
  color: #ffffff;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
          box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

/*Btn Style Three*/
.btn-style-three {
  position: relative;
  font-size: 14px;
  line-height: 30px;
  color: #ffffff;
  padding: 12px 60px;
  font-weight: 600;
  background: var(--bg-theme-color1);
  letter-spacing: 0.05em;
  border-radius: 50px;
  overflow: hidden;
  text-transform: uppercase;
}
.btn-style-three:hover {
  background-color: var(--bg-theme-color2);
  color: #ffffff;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
          box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

/*Btn Style Four*/
.btn-style-four {
  position: relative;
  font-size: 14px;
  line-height: 30px;
  color: var(--theme-color1);
  padding: 12px 60px;
  font-weight: 600;
  background: var(--bg-theme-color4);
  letter-spacing: 0.05em;
  border-radius: 50px;
  overflow: hidden;
  text-transform: uppercase;
}

.btn-style-four:hover {
  background-color: var(--bg-theme-color2);
  color: #ffffff;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.theme-btn.small {
  padding: 10px 40px;
  line-height: 20px;
  font-size: 12px;
}

/*** 

====================================================================
  Anim Icons
====================================================================

***/
.anim-icons {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
}

.anim-icons.full-width {
  max-width: 100%;
}

.anim-icons .icon {
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.bounce-y {
  -webkit-animation: bounce-y 10s infinite linear;
          animation: bounce-y 10s infinite linear;
}

.bounce-x {
  -webkit-animation: bounce-x 10s infinite linear;
          animation: bounce-x 10s infinite linear;
}

.zoom-one {
  -webkit-animation: zoom-one 10s infinite linear;
          animation: zoom-one 10s infinite linear;
}

.overlay-anim {
  position: relative;
}
.overlay-anim::before {
  background: rgba(255, 255, 255, 0.3);
  bottom: 0;
  content: "";
  left: 50%;
  position: absolute;
  right: 51%;
  top: 0;
  opacity: 1;
  z-index: 9;
  pointer-events: none;
  -webkit-transition: all 500ms linear;
  transition: all 500ms linear;
}
.overlay-anim:hover::before {
  left: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 500ms linear;
  transition: all 500ms linear;
}

.overlay-anim {
  position: relative;
}
.overlay-anim::after {
  background: rgba(255, 255, 255, 0.3);
  bottom: 50%;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 50%;
  opacity: 1;
  pointer-events: none;
  -webkit-transition: all 600ms linear;
  transition: all 600ms linear;
}
.overlay-anim:hover::after {
  top: 0;
  bottom: 0;
  opacity: 0;
  -webkit-transition: all 600ms linear;
  transition: all 600ms linear;
}

@-webkit-keyframes float {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes float {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@-webkit-keyframes bounce-y {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes bounce-y {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@-webkit-keyframes bounce-x {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(30px);
            transform: translateX(30px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@keyframes bounce-x {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(30px);
            transform: translateX(30px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@-webkit-keyframes zoom-one {
  0% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  50% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  100% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
}
@keyframes zoom-one {
  0% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  50% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  100% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
}
@-webkit-keyframes wind {
  0% {
    -webkit-transform: rotate(-20deg);
            transform: rotate(-20deg);
  }
  50% {
    -webkit-transform: rotate(20deg);
            transform: rotate(20deg);
  }
  100% {
    -webkit-transform: rotate(-20deg);
            transform: rotate(-20deg);
  }
}
@keyframes wind {
  0% {
    -webkit-transform: rotate(-20deg);
            transform: rotate(-20deg);
  }
  50% {
    -webkit-transform: rotate(20deg);
            transform: rotate(20deg);
  }
  100% {
    -webkit-transform: rotate(-20deg);
            transform: rotate(-20deg);
  }
}
/*=======================
Scroll To Top style
=======================*/
.scroll-to-top {
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 40px;
  font-size: 16px;
  line-height: 40px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  background-color: var(--theme-color2);
  z-index: 100;
  display: none;
  border-radius: 50%;
  margin: 0 auto;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.scroll-to-top:hover {
  background: var(--theme-color1);
  color: #ffffff;
}

/*** 

====================================================================
  Search Popup
====================================================================

***/
.search-popup {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  overflow: hidden;
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

.search-popup .search-back-drop {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: var(--bg-theme-color1);
  opacity: 0.95;
}

.moblie-search-active .search-popup {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1);
          transform: scale(1);
  border-radius: 0%;
}

.search-popup .close-search {
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 26px;
  color: var(--theme-color3);
  z-index: 3;
  border-radius: 50%;
  background-color: transparent;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.search-popup .search-inner {
  position: relative;
  display: block;
  top: 40%;
  height: auto;
  z-index: 1;
  width: calc(100% - 60px);
  max-width: 800px;
  margin: auto;
  opacity: 0;
  -webkit-transform: translateY(-50px);
          transform: translateY(-50px);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.moblie-search-active .search-popup .search-inner {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition-delay: 500ms;
          transition-delay: 500ms;
}

/* Header Search */
.search-popup .form-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.search-popup .form-group input[type=text],
.search-popup .form-group input[type=search] {
  position: relative;
  display: block;
  line-height: 20px;
  font-size: 16px;
  width: 100%;
  height: 50px;
  border: 1px solid #e1e6dc;
  padding: 15px 20px;
  color: #707070;
  background: #ffffff;
  border-radius: 50px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.search-popup .form-group input[type=text]:focus,
.search-popup .form-group input[type=search]:focus {
  border-color: var(--border-theme-color2);
}

.search-popup .form-group button {
  position: absolute;
  right: 5px;
  top: 5px;
  height: 40px;
  width: 40px;
  display: block;
  font-size: 14px;
  color: #fff;
  line-height: 40px;
  border-radius: 50px;
  font-weight: normal;
  background: var(--theme-color3);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.search-popup .form-group button:hover {
  background: var(--theme-color2);
  color: #ffffff;
}

.search-popup input::-webkit-input-placeholder,
.search-popup textarea::-webkit-input-placeholder {
  color: inherit;
}

.search-popup input::-moz-placeholder,
.search-popup textarea::-moz-placeholder {
  color: inherit;
}

.search-popup input:-ms-input-placeholder,
.search-popup textarea:-ms-input-placeholder {
  color: inherit;
}

.search-popup input::-ms-input-placeholder,
.search-popup textarea::-ms-input-placeholder {
  color: inherit;
}

.search-popup input::-webkit-input-placeholder,
.search-popup textarea::-webkit-input-placeholder {
  color: inherit;
}

.search-popup input::-moz-placeholder,
.search-popup textarea::-moz-placeholder {
  color: inherit;
}

.search-popup input:-ms-input-placeholder,
.search-popup textarea:-ms-input-placeholder {
  color: inherit;
}

.search-popup input::-ms-input-placeholder,
.search-popup textarea::-ms-input-placeholder {
  color: inherit;
}

.search-popup input::-webkit-input-placeholder, .search-popup textarea::-webkit-input-placeholder {
  color: inherit;
}

.search-popup input::-moz-placeholder, .search-popup textarea::-moz-placeholder {
  color: inherit;
}

.search-popup input:-ms-input-placeholder, .search-popup textarea:-ms-input-placeholder {
  color: inherit;
}

.search-popup input::-ms-input-placeholder, .search-popup textarea::-ms-input-placeholder {
  color: inherit;
}

.search-popup input::placeholder,
.search-popup textarea::placeholder {
  color: inherit;
}



/*** 

====================================================================
Section Title
====================================================================

***/
.sec-title {
  position: relative;
  margin-bottom: 30px;
}
.sec-title .sub-title {
  font-size: 16px;
  color: #819291;
  display: inline-block;
  font-weight: 700;
}
.sec-title h1 {
  position: relative;
  font-size: 72px;
  color: var(--theme-color1);
  line-height: 1em;
  font-weight: 800;
}
.sec-title h2 {
  position: relative;
  font-size: 40px;
  text-transform: capitalize;
  color: var(--theme-color1);
  line-height: 1.2em;
  font-weight: 800;
}
.sec-title .divider {
  display: inline-block;
  height: 31px;
  width: 27px;
  background-repeat: no-repeat;
  margin-top: 10px;
  -webkit-animation: wind 4s infinite linear;
          animation: wind 4s infinite linear;
}
.sec-title .text {
  font-size: 16px;
  line-height: 30px;
  color: #819291;
  margin-top: 20px;
}

.sec-title.light h1,
.sec-title.light h2,
.sec-title.light .text {
  color: #ffffff;
}
.sec-title.light .sub-title {
  color: #b3c2c1;
}

/*** 

====================================================================
Page Title
====================================================================

***/
.page-title {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 250px;
}

.page-title:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #7394531f;
  opacity: 0.5;
  content: "";
}

.page-title h2 {
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 17px;
}

.page-title .text {
  position: relative;
  color: #ffffff;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.05em;
  max-width: 520px;
}

.page-breadcrumb {
  position: relative;
  margin-top: 5px;
}

.page-breadcrumb li {
  position: relative;
  display: inline-block;
  margin-right: 12px;
  padding-right: 13px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}

.page-breadcrumb li:after {
  position: absolute;
  content: "\f105";
  right: -6px;
  top: 1px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
  color: rgba(255, 255, 255, 0.6);
}

.page-breadcrumb li:last-child::after {
  display: none;
}

.page-breadcrumb li:last-child {
  padding-right: 0px;
  margin-right: 0px;
}

.page-breadcrumb li a {
  color: rgba(255, 255, 255, 0.6);
  font-weight: 500;
  text-transform: capitalize;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.page-breadcrumb li a:hover {
  color: #ffffff;
}

.page-title.style-two {
  background-position: center center;
}

.page-title.style-two .page-breadcrumb-outer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 10px 0;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  z-index: 8;
}

.page-title.style-two .page-breadcrumb li {
  color: rgb(7, 7, 16);
  font-weight: 600;
}

.page-title.style-two .page-breadcrumb li:after {
  color: rgb(7, 7, 16);
}

.page-title.style-two .page-breadcrumb li a {
  color: rgba(7, 7, 16, 0.6);
}

.page-title.style-two .page-breadcrumb li a:hover {
  color: rgb(7, 7, 16);
}

.play-now {
  position: relative;
  display: block;
  z-index: 9;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.play-now .icon {
  position: relative;
  display: inline-block;
  height: 70px;
  width: 70px;
  text-align: center;
  line-height: 70px;
  background-color: #ffffff;
  color: #ff6d2e;
  z-index: 1;
  padding-left: 5px;
  font-size: 14px;
  display: block;
  border-radius: 50%;
  -webkit-box-shadow: 0 0px 10px 0 rgba(255, 255, 255, 0.3);
  -ms-box-shadow: 0 0px 10px 0 rgba(255, 255, 255, 0.3);
  -o-box-shadow: 0 0px 10px 0 rgba(255, 255, 255, 0.3);
  box-shadow: 0 0px 10px 0 rgba(255, 255, 255, 0.3);
  -webkit-transform-origin: center;
  transform-origin: center;
}

.play-now .ripple,
.play-now .ripple:before,
.play-now .ripple:after {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 70px;
  width: 70px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -ms-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -o-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -webkit-animation: ripple 3s infinite;
  animation: ripple 3s infinite;
}

.play-now .ripple:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
  content: "";
  position: absolute;
}

.play-now .ripple:after {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  content: "";
  position: absolute;
}

@-webkit-keyframes ripple {
  70% {
    -webkit-box-shadow: 0 0 0 70px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 70px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
@keyframes ripple {
  70% {
    -webkit-box-shadow: 0 0 0 70px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 70px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
.background-image {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-repeat: repeat;
  background-position: center;
  background-size: cover;
}

/*** 

====================================================================
  Main Slider
====================================================================

***/
.main-slider {
  position: relative;
}
.main-slider h2 {
  position: relative;
  display: block;
  font-size: 90px;
  line-height: 1em;
  color: #ffffff;
  font-weight: 800;
  letter-spacing: -0.05em;
  text-transform: capitalize;
}
.main-slider .text {
  font-size: 18px;
  line-height: 36px;
  color: rgba(255, 255, 255, 0.8);
}
.main-slider .btn-box {
  position: relative;
}
.main-slider .theme-btn {
  display: inline-block;
  white-space: nowrap;
  min-width: 200px !important;
}

.tp-dottedoverlay {
  background-color: var(--bg-theme-color1);
  display: none;
}

.tp-bannertimer {
  display: none;
}

/*** 

====================================================================
    Banner Section
====================================================================

***/
.banner-section {
  position: relative;
  background-color: var(--bg-theme-color1);
  padding: 110px 0px 0;
}
.banner-section:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.02;
  content: "";
  pointer-events: none;
}
.banner-section:after {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 80px;
  width: 100%;
  background-color: var(--theme-color2);
  content: "";
  pointer-events: none;
}

.banner-section .large-container {
  max-width: 1630px;
}

.banner-section .social-icon-two {
  position: absolute;
  left: 5%;
  top: 50%;
  -webkit-transform: translateY(-10%);
          transform: translateY(-10%);
}

.banner-section .float-text {
  position: absolute;
  right: 1%;
  top: 55%;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 2px;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 600;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.banner-section .slide-item {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 10px;
  overflow: hidden;
}
.banner-section .slide-item::before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: var(--bg-theme-color1);
  opacity: 0.55;
  content: "";
  pointer-events: none;
}
.banner-section .slide-item:after {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  content: "";
  pointer-events: none;
}

.banner-section .content-box {
  position: relative;
  padding: 140px 0;
  text-align: center;
}
.banner-section .content-box .icon-leaf {
  position: absolute;
  right: 200px;
  top: 140px;
  height: 103px;
  width: 118px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  -webkit-animation: zoom-one 5s infinite linear;
          animation: zoom-one 5s infinite linear;
}
.banner-section .content-box .sub-title {
  display: block;
  font-size: 18px;
  line-height: 36px;
  color: #ffffff;
  font-weight: 400;
  margin-bottom: 15px;
}
.banner-section .content-box h1 {
  display: block;
  font-size: 90px;
  line-height: 1em;
  color: #ffffff;
  font-weight: 800;
  letter-spacing: -0.05em;
  margin-bottom: 35px;
}

/* Animate 1 */
.owl-carousel .animate-7,
.owl-carousel .animate-6,
.owl-carousel .animate-5,
.owl-carousel .animate-4,
.owl-carousel .animate-3,
.owl-carousel .animate-2,
.owl-carousel .animate-1 {
  opacity: 0;
  -webkit-transform: translateY(100px);
  transform: translateY(100px);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.owl-carousel .animate-x {
  opacity: 0;
  -webkit-transform: translateX(100px);
  transform: translateX(100px);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.owl-carousel .active .animate-1,
.owl-carousel .active .animate-2,
.owl-carousel .active .animate-3,
.owl-carousel .active .animate-4,
.owl-carousel .active .animate-5,
.owl-carousel .active .animate-6,
.owl-carousel .active .animate-7 {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

/* Animate 2 */
.owl-carousel .active .animate-2 {
  -webkit-transition-delay: 300ms;
  transition-delay: 300ms;
}

/* Animate 3 */
.owl-carousel .active .animate-3 {
  -webkit-transition-delay: 600ms;
  transition-delay: 600ms;
}

/* Animate 4 */
.owl-carousel .active .animate-4 {
  -webkit-transition-delay: 900ms;
  transition-delay: 900ms;
}

/* Animate 5 */
.owl-carousel .active .animate-5 {
  -webkit-transition-delay: 1200ms;
  transition-delay: 1200ms;
}

/* Animate 6 */
.owl-carousel .active .animate-6 {
  -webkit-transition-delay: 1500ms;
  transition-delay: 1500ms;
}

/* Animate 7 */
.owl-carousel .active .animate-7 {
  -webkit-transition-delay: 1800ms;
  transition-delay: 1800ms;
}

.banner-carousel {
  background: var(--bg-theme-color1);
  border-radius: 10px;
}

.banner-carousel .owl-nav {
  position: absolute;
  left: 60px;
  top: 50%;
  max-width: 1400px;
  margin: 0 auto;
  margin-top: -65px;
}

.banner-carousel .owl-next,
.banner-carousel .owl-prev {
  display: block;
  margin: 5px 0;
  height: 50px;
  width: 50px;
  color: var(--theme-color1);
  border-radius: 50%;
  border: 2px solid var(--border-theme-color1);
  font-size: 20px;
  line-height: 48px;
  font-weight: 700;
  text-align: center;
  opacity: 0.2;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  background-color: #7db118 !important;
}

.banner-carousel .owl-next:hover,
.banner-carousel .owl-prev:hover {
  opacity: 1;
}

.banner-carousel .owl-dots {
  position: absolute;
  left: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  margin-bottom: 50px;
}
.banner-carousel .owl-dots .owl-dot {
  height: 13px;
  width: 13px;
  border: 2px solid transparent;
  background-color: rgba(255, 255, 255, 0.4);
  margin: 0 5px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  border-radius: 50%;
}
.banner-carousel .owl-dots .owl-dot.active {
  background-color: #ffffff;
  border-color: #ffffff;
  border-radius: 50%;
}

/*** 

====================================================================
  Banners Section
====================================================================

***/
.banners-section {
  position: relative;
  padding: 120px 0px 90px;
}

.banners-section .large-container {
  max-width: 1630px;
}

.banner-box-one {
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
  max-width: 665px;
  width: 100%;
  height: 100%;
}

.banner-box-one .inner-box {
  position: relative;
  background: var(--theme-color3);
  padding: 75px 50px;
  border-radius: 10px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 380px;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.banner-box-one .image {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.banner-box-one .image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.banner-box-one .content {
  position: relative;
  z-index: 1;
}
.banner-box-one .content .title {
  display: block;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
  letter-spacing: 0.04em;
  color: var(--theme-color3);
  text-transform: uppercase;
}
.banner-box-one .content h3 {
  font-size: 50px;
  line-height: 1.2em;
  color: #ffffff;
  font-weight: 800;
  margin-bottom: 20px;
}
.banner-box-one .content .theme-btn {
  padding: 10px 40px;
}

/* ====================
    Banner Section Two
=======================*/
.banner-box-two {
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
  max-width: 420px;
  width: 100%;
}

.banner-box-two .inner-box {
  position: relative;
  background: var(--theme-color3);
  padding: 60px 50px;
  border-radius: 10px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  min-height: 380px;
}

.banner-box-two .image {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.banner-box-two .image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.banner-box-two .content {
  position: relative;
}
.banner-box-two .content .title {
  display: block;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
  letter-spacing: 0.04em;
  color: #ffffff;
  margin-bottom: 30px;
}
.banner-box-two .content h3 {
  font-size: 70px;
  line-height: 1.2em;
  color: #ffffff;
  font-weight: 200;
  margin-bottom: 20px;
}
.banner-box-two .content .theme-btn {
  padding: 10px 40px;
}

/* ====================
    Banner Section Three
=======================*/
.banner-box-three {
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
  max-width: 540px;
  width: 100%;
}

.banner-box-three .inner-box {
  position: relative;
  background: var(--theme-color3);
  padding: 75px 50px;
  border-radius: 10px;
  overflow: hidden;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 380px;
}

.banner-box-three .image {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.banner-box-three .image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.banner-box-three .content {
  position: relative;
}
.banner-box-three .content .title {
  display: block;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
  letter-spacing: 0.04em;
  color: var(--theme-color3);
  text-transform: uppercase;
}
.banner-box-three .content h3 {
  font-size: 50px;
  line-height: 1.2em;
  color: var(--border-theme-color1);
  font-weight: 800;
  margin-bottom: 20px;
}
.banner-box-three .content .theme-btn {
  padding: 10px 40px;
}

/* ====================
    Banner Section Four
=======================*/
.banner-box-four {
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
}

.banner-box-four .inner-box {
  position: relative;
  padding: 55px 60px;
  border-radius: 10px;
  overflow: hidden;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 340px;
}

.banner-box-four .image {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.banner-box-four .image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  border-radius: 10px;
}
.banner-box-four .image:before {
  position: absolute;
  left: 50%;
  top: 75px;
  height: 40px;
  width: 37px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  content: "";
  -webkit-animation: bounce-y 30s infinite linear;
          animation: bounce-y 30s infinite linear;
}

.banner-box-four .content {
  position: relative;
  z-index: 1;
}
.banner-box-four .content h3 {
  font-size: 40px;
  line-height: 1.2em;
  color: #ffffff;
  font-weight: 400;
  margin-bottom: 30px;
}
.banner-box-four .content .theme-btn {
  padding: 10px 40px;
}

.banner-box-four:nth-child(2) .image:before {
  -webkit-animation-delay: 15s;
          animation-delay: 15s;
}

/*** 

====================================================================
	Features Section
====================================================================

***/
.features-section {
  position: relative;
  background-color: var(--theme-color2);
  padding: 0px 0 40px;
  z-index: 2;
}
.features-section::before {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 16px;
  width: 100%;
  content: "";
}
.features-section::after {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 165px;
  width: 100%;
  opacity: 0.5;
  content: "";
}

.features-section .large-container {
  max-width: 1630px;
}

.feature-block {
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
}

.feature-block .inner {
  position: relative;
  min-height: 70px;
  padding-left: 80px;
}
.feature-block .inner:hover .icon:after {
  opacity: 1;
}
.feature-block .inner:hover .icon:before {
  -webkit-transform: scale(-1) rotate(180deg);
          transform: scale(-1) rotate(180deg);
}
.feature-block .inner:hover .icon {
  color: var(--theme-color2);
}

.feature-block .icon {
  position: relative;
  text-align: center;
  display: block;
  width: 100px;
  padding-top: 50px;
  font-size: 58px;
  line-height: 1em;
  color: #ffffff;
  min-height: 140px;
  margin-bottom: 15px;
}
.feature-block .icon:before {
  position: relative;
  display: block;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  z-index: 1;
}
.feature-block .icon:after {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  content: "";
  background-color: var(--bg-theme-color1);
  border-radius: 0 0 50px 50px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  opacity: 0.1;
}

.feature-block h5 {
  position: relative;
  display: block;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  font-weight: 600;
  margin-bottom: 6px;
}
.feature-block h5 a {
  color: #ffffff;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.feature-block h5 a:hover {
  color: #fff;
}

.feature-block .text {
  font-size: 16px;
  line-height: 1.4em;
  color: #ffffff;
}

/*** 

====================================================================
    Services Section
====================================================================

***/
.services-section {
  position: relative;
}

.services-section .auto-container {
  max-width: 1280px;
}

.services-section .outer-box {
  position: relative;
  padding: 60px 60px;
  background-color: #fff;
  border-radius: 10px;
}

.services-section.pull-up .outer-box {
  margin-top: -60px;
}

.service-block {
  position: relative;
}

.service-block .inner-box {
  position: relative;
  border-radius: 10px;
  text-align: center;
  padding-bottom: 45px;
  overflow: hidden;
}
.service-block .inner-box:hover .overlay-content .icon:before {
  -webkit-transform: scale(-1) rotate(180deg);
          transform: scale(-1) rotate(180deg);
}
.service-block .inner-box:hover .overlay-content {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
  visibility: visible;
}
.service-block .inner-box:hover .content {
  opacity: 0;
  visibility: hidden;
}
.service-block .inner-box:hover .image img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.service-block .image {
  position: relative;
  margin-bottom: 0;
  border-radius: 10px;
  overflow: hidden;
}
.service-block .image:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  content: "";
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(153, 153, 153, 0)), to(var(--theme-color1)));
  background: linear-gradient(to bottom, rgba(153, 153, 153, 0) 0%, var(--theme-color1) 100%);
  border-radius: 10px;
  z-index: 1;
}
.service-block .image img {
  display: block;
  border-radius: 10px;
  width: 100%;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.service-block h4 {
  font-size: 24px;
  line-height: 1em;
  /* color: #fff; */
  font-weight: 700;
  margin-bottom: 20px;
}
.service-block h4 a {
  color: #fff;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.service-block h4 a:hover {
  color: var(--theme-color2);
}

.service-block .icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 90px;
  width: 90px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #fff;
  font-size: 48px;
  background-color: var(--bg-theme-color2);
  border-radius: 50%;
}
.service-block .icon:before {
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.service-block .text {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  line-height: 24px;
}

.service-block .content {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}

.service-block .overlay-content {
  position: absolute;
  left: 0;
  bottom: 0;
  height: auto;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  background-color: var(--bg-theme-color1);
  z-index: 1;
  padding: 0 35px 35px;
  border-radius: 0 0 10px 10px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}
.service-block .overlay-content .icon {
  position: relative;
  margin-top: -45px;
  background-color: var(--bg-theme-color3);
  margin-bottom: 20px;
}

/***

==================================================================
    About Section
==================================================================

***/
.about-section {
  position: relative;
  padding: 60px 0 70px;
  z-index: 1;
}

.about-section:before {
  position: absolute;
  right: 0;
  top: 0;
  height: 826px;
  width: 100%;
  content: "";
}

.about-section .content-column {
  position: relative;
  margin-bottom: 50px;
}

.about-section .content-column .inner-column {
  position: relative;
  padding-left: 70px;
}
.about-section .content-column .inner-column .sec-title {
  margin-bottom: 30px;
}
.about-section .content-column .inner-column .info-box {
  position: relative;
  padding-right: 200px;
  margin-bottom: 40px;
}
.about-section .content-column .inner-column .video-box {
  position: absolute;
  right: 0;
  top: 0;
  padding: 14px;
  border: 1px solid var(--bg-theme-color3);
  border-radius: 5px;
  overflow: hidden;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.about-section .content-column .inner-column .video-box img {
  border-radius: 10px;
}
.about-section .content-column .inner-column .video-box .icon {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 45px;
  width: 45px;
  background-color: var(--bg-theme-color3);
  color: #ffffff;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.about-section .content-column .inner-column .video-box:hover {
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
          box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.about-section .image-column {
  position: relative;
  margin-bottom: 50px;
}

.about-section .about-image-wrapper {
  position: relative;
  z-index: 1;
}
.about-section .about-image-wrapper .bg-shape {
  position: absolute;
  top: -40px;
  left: -70px;
}
.about-section .about-image-wrapper .image-1 {
  position: relative;
  display: inline-block;
  margin-bottom: 0;
}
.about-section .about-image-wrapper .image-1:before {
  position: absolute;
  left: 70px;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #f6f5f0;
  border-radius: 50%;
  content: "";
}
.about-section .about-image-wrapper .image-1:after {
  position: absolute;
  left: 15%;
  top: 15%;
  height: 70%;
  width: 70%;
  border: 3px solid #ffffff;
  border-radius: 50%;
  -webkit-animation: zoom-one 10s infinite linear 2s;
          animation: zoom-one 10s infinite linear 2s;
  content: "";
}
.about-section .about-image-wrapper .image-1 img {
  position: relative;
  height: 550px;
  min-width: 550px;
  width: 550px;
  border-radius: 50%;
}
.about-section .about-image-wrapper .image-2 {
  position: absolute;
  bottom: -60px;
  right: -60px;
  margin-bottom: 0;
}
.about-section .about-image-wrapper .image-2 img {
  max-width: 100%;
  height: auto;
}

/***

==================================================================
    About Section Two
==================================================================

***/
.about-section-two {
  position: relative;
  padding: 120px 0 70px;
  background-color: var(--bg-theme-color1);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 1;
}

.about-section-two .content-column {
  position: relative;
  margin-bottom: 50px;
}
.about-section-two .content-column .inner-column {
  position: relative;
}
.about-section-two .content-column .text {
  font-size: 16px;
  line-height: 30px;
  color: #b3c2c1;
}
.about-section-two .content-column .theme-btn {
  margin-top: 40px;
}

.about-block {
  position: relative;
}
.about-block .inner-box {
  padding-left: 95px;
  padding-right: 10px;
  margin-bottom: 30px;
  min-height: 82px;
}
.about-block .inner-box:hover .icon {
  -webkit-transform: scale(-1) rotate(180deg);
          transform: scale(-1) rotate(180deg);
  background-color: var(--bg-theme-color3);
  color: #fff;
}
.about-block .title {
  font-size: 16px;
  color: #b3c2c1;
  line-height: 24px;
}
.about-block h5 {
  position: relative;
  font-size: 20px;
  color: #ffffff;
  font-weight: 600;
  margin-bottom: 10px;
}
.about-block .icon {
  position: absolute;
  left: 0;
  top: 0;
  height: 80px;
  width: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 36px;
  color: var(--theme-color3);
  border: 4px solid var(--border-theme-color3);
  border-radius: 50%;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.about-section-two .fact-counter-one {
  position: absolute;
  left: -15px;
  top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 135px;
  width: 135px;
  background-color: #fff;
  border-radius: 50%;
  margin-bottom: 40px;
}
.about-section-two .fact-counter-one:before {
  position: absolute;
  right: 22px;
  bottom: 10px;
  height: 10px;
  width: 10px;
  background: #fff;
  content: "";
}

.fact-counter-one .count-box {
  position: relative;
  font-size: 30px;
  color: var(--theme-color1);
  line-height: 1em;
  font-weight: 700;
}

.fact-counter-one .counter-title {
  display: block;
  font-size: 12px;
  line-height: 20px;
  font-weight: 700;
  text-transform: uppercase;
  color: #819291;
}

.about-section-two .image-column {
  position: relative;
  margin-bottom: 50px;
}

.about-section-two .image-box {
  position: relative;
  margin-right: -200px;
  margin-top: -10px;
  padding-left: 60px;
}
.about-section-two .image-box .image {
  position: relative;
  margin-bottom: 0;
}
.about-section-two .image-box .image:before {
  position: absolute;
  top: 130px;
  left: -25px;
  height: 64px;
  width: 64px;
  background-color: var(--bg-theme-color2);
  border-radius: 50%;
  -webkit-animation: zoom-one 3s infinite linear;
          animation: zoom-one 3s infinite linear;
  content: "";
}
.about-section-two .image-box .image img {
  width: 100%;
  -webkit-filter: drop-shadow(0 20px 40px rgba(0, 0, 0, 0.4));
          filter: drop-shadow(0 20px 40px rgba(0, 0, 0, 0.4));
}

/***

==================================================================
    About Section Three
==================================================================

***/
.about-section-three {
  position: relative;
  padding: 120px 0 70px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 1;
}

.about-section-three .content-column {
  position: relative;
  margin-bottom: 50px;
}

.about-section-three .content-column .inner-column {
  position: relative;
}
@media screen and (min-width: 768px) {
  .about-section-three .content-column .inner-column {
    padding-left: 40px;
  }

}

.feature-list {
  position: relative;
  margin: 0 -15px 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.feature-list li {
  position: relative;
  padding: 0 15px;
  max-width: 50%;
  width: 100%;
  margin-bottom: 30px;
}
.feature-list li .inner {
  position: relative;
  background-color: #ffffff;
  font-size: 15px;
  color: var(--theme-color1);
  font-weight: 700;
  padding: 15px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
          box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
@media screen and (min-width: 768px) {
  .feature-list li .inner {
    font-size: 18px !important;
  line-height: 30px !important;
  }
}
.feature-list li .inner:hover {
  background-color: var(--bg-theme-color1);
  color: #fff;
}
.feature-list li .inner i {
  line-height: 30px;
  margin-right: 10px;
  font-size: 18px;
  color: var(--theme-color3);
}

.founder-info {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-height: 88px;
  padding-left: 102px;
}
.founder-info .thumb {
  position: absolute;
  left: 0;
  top: 0;
  height: 88px;
  width: 88px;
  padding: 5px;
  background-color: #fff;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid var(--bg-theme-color3);
}
.founder-info .thumb img {
  border-radius: 50%;
}
.founder-info .name {
  margin-top: 0;
  margin-bottom: 5px;
  color: var(--bg-theme-color1);
  font-weight: 700;
}
.founder-info .designation {
  font-size: 14px;
  color: #819291;
}

.about-section-three .image-column {
  position: relative;
  margin-bottom: 50px;
}

.about-section-three .image-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.about-section-three .image-box:before {
  position: absolute;
  top: 0;
  left: -120px;
  height: 569px;
  width: 591px;
  -webkit-animation: zoom-one 60s infinite linear;
          animation: zoom-one 60s infinite linear;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  content: "";
}
.about-section-three .image-box .image-1 {
  position: relative;
  margin-right: 30px;
  margin-bottom: 0;
  max-width: 270px;
}
.about-section-three .image-box .image-1 img {
  width: 100%;
  border-radius: 10px;
}
.about-section-three .image-box .image-2 {
  position: relative;
  margin-top: 80px;
  max-width: 270px;
  margin-bottom: 0;
}
.about-section-three .image-box .image-2 img {
  width: 100%;
  border-radius: 10px;
}

.about-section-three .exp-box {
  position: absolute;
  left: 135px;
  bottom: 35px;
  height: 130px;
  width: 260px;
  border-radius: 10px 10px 10px 0;
  background-color: #fff;
  padding: 10px;
  -webkit-animation: bounce-y 5s infinite linear;
          animation: bounce-y 5s infinite linear;
}
.about-section-three .exp-box:before {
  position: absolute;
  left: 0;
  top: 100%;
  content: "";
  border-top: 30px solid #ffffff;
  border-right: 60px solid transparent;
}
.about-section-three .exp-box:after {
  position: absolute;
  left: 10px;
  top: 100%;
  content: "";
  margin-top: -10px;
  border-top: 25px solid var(--border-theme-color3);
  border-right: 50px solid transparent;
  z-index: 1;
}
.about-section-three .exp-box .inner {
  position: relative;
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  color: #ffffff;
  padding: 15px 35px;
  background: var(--theme-color3);
  border-radius: 10px 10px 10px 0;
  height: 100%;
}
.about-section-three .exp-box strong {
  font-size: 50px;
  line-height: 1em;
  color: #ffffff;
  display: block;
}

/*** 

====================================================================
		Video Section
====================================================================

***/
.video-section {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 140px 0 115px;
}
.video-section:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: var(--bg-theme-color1);
  content: "";
  opacity: 0.7;
}
.video-section:after {
  position: absolute;
  left: 25px;
  right: 25px;
  bottom: 25px;
  top: 25px;
  border: 1px solid #ffffff;
  pointer-events: none;
  content: "";
  opacity: 0.2;
}

.video-section .content-box {
  position: relative;
  text-align: center;
}
.video-section .content-box h2 {
  display: block;
  font-size: 60px;
  line-height: 80px;
  color: #ffffff;
  font-weight: 800;
}
.video-section .content-box .with-icons {
  position: relative;
}
.video-section .content-box .with-icons:before, .video-section .content-box .with-icons:after {
  position: absolute;
  left: -130px;
  top: -50px;
  height: 130px;
  width: 130px;
  background-repeat: no-repeat;
  background-position: center;
  content: "";
  -webkit-animation: wind 5s infinite linear;
          animation: wind 5s infinite linear;
}
.video-section .content-box .with-icons:after {
  left: auto;
  right: -130px;
  -webkit-animation-delay: 2.5s;
          animation-delay: 2.5s;
}

.video-section .play-now {
  display: inline-block;
  margin-bottom: 55px;
}
.video-section .play-now .icon {
  height: 90px;
  width: 90px;
  line-height: 90px;
  color: #ffffff;
  background-color: var(--bg-theme-color2);
  padding-left: 5px;
  font-size: 24px;
}

/*** 

====================================================================
		FAQ's Sectiom
====================================================================

***/
.faqs-section {
  position: relative;
  padding: 120px 0 70px;
}
.faqs-section .bg-pattern-left {
  position: absolute;
  right: 100%;
  top: 0;
  height: 100%;
  width: 620px;
  margin-right: -240px;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
}
.faqs-section .bg-pattern-right {
  position: absolute;
  left: 100%;
  top: 75px;
  height: 100%;
  width: 620px;
  margin-right: -240px;
  background-repeat: no-repeat;
  background-position: center left;
}

.faqs-section .faq-column {
  position: relative;
  margin-bottom: 50px;
}
.faqs-section .faq-column .inner-column {
  position: relative;
}
@media screen and (min-width: 768px) {
  .faqs-section .faq-column .inner-column {
    padding-left: 70px;
  }
}

.accordion-box {
  position: relative;
}

.accordion-box .block {
  position: relative;
  background-color: #252522;
  border-radius: 10px;
  margin-bottom: 10px;
}
.accordion-box .block .acc-btn {
  position: relative;
  font-size: 20px;
  line-height: 34px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  padding: 20px 40px;
  padding-right: 70px;
  border-radius: 10px;
  letter-spacing: -0.04em;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.accordion-box .block .acc-btn .icon {
  position: absolute;
  right: 18px;
  top: 18px;
  height: 38px;
  width: 38px;
  font-size: 16px;
  line-height: 38px;
  color: #ffffff;
  border-radius: 50%;
  background-color: var(--bg-theme-color1);
  text-align: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.accordion-box .block .acc-btn.active .icon {
  color: #ffffff;
  background-color: var(--bg-theme-color3);
}
.accordion-box .block .acc-btn.active .icon:before {
  content: "\f107";
}

.accordion-box .block .acc-content {
  position: relative;
  display: none;
}
.accordion-box .block .acc-content .content {
  position: relative;
  padding: 0px 40px 25px;
}
.accordion-box .block .acc-content .content .text {
  display: block;
  font-size: 16px;
  line-height: 30px;
  color: #d3dcdc;
  margin-bottom: 0;
}
.accordion-box .block .acc-content.current {
  display: block;
}

.faqs-section .image-column {
  position: relative;
  margin-bottom: 50px;
}
.faqs-section .image-column .inner-column {
  height: 100%;
}
.faqs-section .image-column .image {
  position: relative;
  margin-bottom: 0;
  height: 100%;
}
.faqs-section .image-column .image:before {
  position: absolute;
  right: 0;
  margin-right: -20px;
  top: 50px;
  bottom: 50px;
  width: 20px;
  border-radius: 0 10px 10px 0;
  background-color: var(--bg-theme-color3);
  content: "";
  z-index: 1;
}
.faqs-section .image-column .image img {
  max-width: 100%;
  border-radius: 10px;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
}

/*** 

====================================================================
    Testimonial Section
====================================================================

***/
.testimonial-section {
  position: relative;
  padding: 120px 0;
}
.testimonial-section:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 540px;
  width: 100%;
  background-position: center top;
  background-size: cover;
  content: "";
}
.testimonial-section .sec-title {
  margin-bottom: 20px;
}
.testimonial-section .outer-box {
  position: relative;
  margin: 0 -15px;
}

.testimonial-block {
  position: relative;
  padding: 0 15px 15px;
}

.testimonial-block .inner-box {
  position: relative;
  background: #ffffff;
  border-top: 10px solid var(--bg-theme-color2);
  border-radius: 15px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  text-align: center;
  -webkit-box-shadow: 0 0px 15px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0px 15px rgba(0, 0, 0, 0.1);
  padding: 0 60px 50px;
  margin-top: 80px;
}
.testimonial-block .inner-box:before {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 215px;
  width: 168px;
  content: "";
  opacity: 0.05;
}
.testimonial-block .inner-box:hover {
  background-color: var(--bg-theme-color1);
}
.testimonial-block .inner-box:hover .thumb img {
  border: 10px solid var(--bg-theme-color2);
}
.testimonial-block .inner-box:hover .icon {
  background-color: #fff;
  color: var(--theme-color1);
}
.testimonial-block .inner-box:hover .text {
  color: #b3c2c1;
}
.testimonial-block .inner-box:hover .name {
  color: var(--theme-color2);
}
.testimonial-block .inner-box:hover .designation {
  color: #b3c2c1;
}

.testimonial-block .thumb {
  position: relative;
  display: inline-block;
  height: 160px;
  width: 160px;
  margin-top: -80px;
}
.testimonial-block .thumb img {
  display: block;
  width: 100%;
  border-radius: 50%;
  border: 10px solid transparent;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.testimonial-block .icon {
  position: absolute;
  font-size: 14px;
  color: #fff;
  height: 54px;
  width: 54px;
  text-align: center;
  line-height: 54px;
  left: 0;
  right: 0;
  margin: 0 auto -17px;
  bottom: 0;
  border-radius: 50%;
  background-color: var(--bg-theme-color2);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.testimonial-block .text {
  position: relative;
  font-size: 20px;
  line-height: 36px;
  color: #819291;
  font-weight: 400;
  margin-top: 15px;
  margin-bottom: 30px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.testimonial-block .name {
  position: relative;
  display: block;
  font-size: 20px;
  font-weight: 700;
  color: var(--theme-color1);
  line-height: 1.2em;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.testimonial-block .designation {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 22px;
  color: #819291;
  font-weight: 600;
  letter-spacing: 0.01em;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

/*** 

====================================================================
	Testimonial Section Two
====================================================================

***/
.testimonial-section-two {
  position: relative;
  padding: 120px 0;
}

.testimonial-section-two .icon-leaf {
  position: absolute;
  background-size: cover;
}
.testimonial-section-two .icon-leaf.leaf-1 {
  right: -70px;
  bottom: 50px;
  height: 50px;
  width: 46px;
  -webkit-animation: fa-spin 10s infinite linear;
          animation: fa-spin 10s infinite linear;
  opacity: 0.5;
}
.testimonial-section-two .icon-leaf.leaf-2 {
  left: -240px;
  top: 0px;
  height: 76px;
  width: 70px;
  -webkit-animation: fa-spin 10s infinite linear;
          animation: fa-spin 10s infinite linear;
  opacity: 0.5;
}
.testimonial-section-two .icon-leaf.leaf-3 {
  right: -260px;
  top: -10px;
  height: 149px;
  width: 138px;
  opacity: 0.5;
  -webkit-animation: fa-spin 10s infinite linear;
          animation: fa-spin 10s infinite linear;
}

.testimonial-section-two .testimonials {
  position: relative;
}

.testimonial-block-two {
  position: relative;
}

.testimonial-block-two .inner-box {
  position: relative;
  padding-left: 270px;
  background-color: #ffffff;
}

.testimonial-block-two .thumb {
  position: absolute;
  left: 0;
  top: 0;
  height: 170px;
  width: 170px;
  left: 28px;
}
.testimonial-block-two .thumb img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
}
.testimonial-block-two .thumb .icon {
  position: absolute;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 50px;
  width: 50px;
  color: #fff;
  border-radius: 50%;
  background-color: var(--bg-theme-color3);
}

.testimonial-block-two .text {
  position: relative;
  top: -5px;
  font-size: 30px;
  line-height: 50px;
  color: var(--theme-color1);
  font-weight: 500;
  margin-bottom: 25px;
}

.testimonial-block-two .name {
  position: relative;
  display: block;
  font-size: 20px;
  font-weight: 800;
  color: var(--theme-color3);
  line-height: 30px;
}

.testimonial-block-two .designation {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 24px;
  color: #819291;
  font-weight: 500;
}

.testimonial-thumbs {
  position: absolute;
  left: 0;
  top: 190px;
}

.testimonial-thumb {
  position: relative;
  cursor: pointer;
  z-index: 9;
}

.testimonial-thumb .image {
  position: relative;
  margin-bottom: 0;
}

.testimonial-thumb .image img {
  height: 72px;
  width: 72px;
  border-radius: 50%;
  border: 2px solid transparent;
}

.testimonial-thumb.swiper-slide-thumb-active .image img {
  border: 2px solid #ffc737;
}

.testimonials .testimonial-pagination {
  position: absolute;
  right: 0;
  bottom: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  z-index: 9;
}
.testimonials .testimonial-pagination .swiper-pagination-bullet {
  position: relative;
  height: 13px;
  width: 13px;
  display: block;
  background: var(--bg-theme-color2);
  margin-left: 5px;
  border-radius: 10px;
  border: 2px solid #ffffff;
  display: block;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  opacity: 0.4;
}
.testimonials .testimonial-pagination .swiper-pagination-bullet-active {
  border-color: var(--theme-color2);
  opacity: 1;
}

.testimonial-content .swiper-slide:not(.swiper-slide-active) {
  opacity: 0 !important;
}

/*** 

====================================================================
Contact Section
====================================================================

***/
.contact-section {
  position: relative;
  padding: 120px 0;
  margin-top: 76px;
  background-color: #ffffff;
  z-index: 1;
}
.contact-section:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  max-height: 16px;
  content: "";
  top: -16px;
}
.contact-section .main-bg {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  max-height: 760px;
  pointer-events: none;
}
.contact-section .main-bg .bg-image {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.contact-section .main-bg .bg-image::before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: var(--bg-theme-color1);
  opacity: 0.94;
  content: "";
}
.contact-section .main-bg .bg-pattern {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  max-height: 340px;
  top: auto;
  bottom: -120px;
}
.contact-section .outer-box {
  position: relative;
  background-color: #ffffff;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
          box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
}
.contact-section .form-column {
  position: relative;
}
.contact-section .form-column .inner-column {
  position: relative;
  padding: 80px 80px;
  margin-right: -40px;
  overflow: hidden;
}
.contact-section .form-column .inner-column:before {
  position: absolute;
  right: -30px;
  bottom: -100px;
  height: 234px;
  width: 238px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  content: "";
}
.contact-section .image-column .image {
  padding-left: 40px;
  margin-bottom: 0;
  height: 100%;
}
.contact-section .image-column .image:before {
  position: absolute;
  top: 60px;
  bottom: 60px;
  background-color: var(--bg-theme-color3);
  content: "";
  width: 20px;
  border-radius: 0 10px 10px 0;
}
.contact-section .image-column .image img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.contact-form {
  position: relative;
}

.contact-form .form-group {
  position: relative;
  margin-bottom: 20px;
}

.contact-form .form-group label {
  font-size: 16px;
  line-height: 21px;
  color: #1B212F;
  font-weight: 400;
  display: block;
  margin-bottom: 10px;
}

.contact-form .form-group:last-child {
  margin-bottom: 0;
}

.contact-form .form-group input,
.contact-form .form-group textarea,
.contact-form .form-group select {
  position: relative;
  display: block;
  height: 53px;
  width: 100%;
  font-size: 14px;
  color: var(--theme-color1);
  line-height: 23px;
  font-weight: 500;
  padding: 14px 30px;
  background-color: #f6f5f0;
  border: 1px solid transparent;
  border-radius: 10px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.contact-form .form-group input:focus,
.contact-form .form-group select:focus,
.contact-form .form-group textarea:focus {
  border-color: var(--border-theme-color2);
}

.contact-form .form-group textarea {
  height: 135px;
  resize: none;
}

.contact-form .form-group input[type=submit],
.contact-form .form-group button {
  text-transform: uppercase;
  padding: 11px 60px;
  letter-spacing: 0.1em;
}

.contact-form label.error {
  display: block;
  font-weight: 500;
  font-size: 13px;
  text-transform: capitalize;
  line-height: 24px;
  color: #ff0000;
  margin-bottom: 0;
}

/***

====================================================================
	Why ChoosE uS
====================================================================

***/
.why-choose-us {
  position: relative;
  overflow: hidden;
}

.why-choose-us .large-container {
  max-width: 1590px;
}

.why-choose-us .outer-box {
  position: relative;
  background-color: #f6f5f0;
  background-position: center 160px;
  background-repeat: no-repeat;
  min-height: 720px;
  border-radius: 10px;
  overflow: hidden;
}
.why-choose-us .outer-box:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  max-height: 250px;
  border-radius: 10px;
  content: "";
  z-index: 8;
  pointer-events: none;
}
.why-choose-us .outer-box:after {
  position: absolute;
  left: 0;
  bottom: 0;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  background-position: center bottom;
  background-repeat: no-repeat;
  content: "";
  z-index: 8;
  pointer-events: none;
}

.why-choose-us .image-column {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 9;
}

.why-choose-us .image-column .water {
  position: absolute;
  left: 0;
  right: 0;
  top: 40%;
  margin: 0 auto;
  max-width: 100%;
  width: auto;
  text-align: center;
}

.why-choose-us .image-column .image {
  position: relative;
  margin-bottom: 0;
  -webkit-animation: float 5s infinite linear;
          animation: float 5s infinite linear;
}

.feature-block-two {
  position: relative;
  margin-bottom: 80px;
}

.feature-block-two .inner-box {
  position: relative;
  padding-right: 160px;
  text-align: right;
}

.feature-block-two .icon-box {
  position: absolute;
  right: 30px;
  top: 0;
  text-align: center;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.feature-block-two .icon-box .icon {
  position: relative;
  display: block;
  height: 100px;
  width: 100px;
  font-size: 62px;
  line-height: 50px;
  color: #ffffff;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: var(--bg-theme-color3);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.feature-block-two .inner-box:hover .icon-box .icon {
  background-color: #ffffff;
  color: var(--theme-color3);
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
          box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.feature-block-two h4 {
  position: relative;
  display: block;
  font-size: 20px;
  color: var(--theme-color1);
  font-family: var(--text-font);
  font-weight: 800;
  margin-bottom: 10px;
}

.feature-block-two p {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 30px;
  color: #819291;
}

.why-choose-us .left-column,
.why-choose-us .right-column {
  padding-top: 280px;
}

.why-choose-us .left-column .inner-column,
.why-choose-us .right-column .inner-column {
  position: relative;
}

.why-choose-us .right-column .feature-block-two .inner-box {
  padding-left: 160px;
  padding-right: 0;
  text-align: left;
}

.why-choose-us .right-column .feature-block-two .icon-box {
  right: auto;
  left: 30px;
}

.why-choose-us .left-column .inner-column:before,
.why-choose-us .right-column .inner-column:before {
  position: absolute;
  left: 100%;
  top: 0;
  margin-left: -50px;
  margin-top: -75px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 50px;
  width: 124px;
  z-index: 1;
  content: "";
}

.why-choose-us .right-column .inner-column:before {
  left: auto;
  right: 100%;
  margin-right: -50px;
  margin-left: 0;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}

/*** 

====================================================================
    Fun Fact Section
====================================================================

***/
.fun-fact-section {
  position: relative;
}

.fact-counter {
  position: relative;
  padding: 120px 0 50px;
  margin-top: -65px;
  display: inline-block;
  width: 100%;
}

.fact-counter .counter-column {
  position: relative;
  margin-bottom: 70px;
  text-align: center;
}

.fact-counter .counter-column .icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  height: 130px;
  width: 130px;
  font-size: 62px;
  color: var(--theme-color3);
  background-color: #fff;
  border-radius: 50%;
  border: 10px solid var(--border-theme-color3);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  margin: 0 auto 30px;
}

.fact-counter .counter-column:hover .icon {
  background-color: var(--bg-theme-color3);
  color: #fff;
  -webkit-transform: scale(-1) rotate(180deg);
          transform: scale(-1) rotate(180deg);
}

.fact-counter .count-box {
  position: relative;
  font-size: 60px;
  color: #fff;
  font-weight: 400;
  line-height: 1em;
  margin-bottom: 25px;
}

.fact-counter .count-box .count-text {
  font-size: 60px;
  color: #fff;
  font-weight: 400;
  line-height: 1em;
}

.fact-counter .counter-title {
  display: inline-block;
  font-size: 18px;
  line-height: 25px;
  color: #b3c2c1;
  padding: 9px 50px;
  border: 1px solid rgba(255, 255, 255, 0.4);
}

/*** 

====================================================================
    News Section
====================================================================

***/
.news-section {
  position: relative;
  padding: 120px 0 90px;
  overflow: hidden;
}
.news-section:before {
  position: absolute;
  left: 0;
  top: -140px;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center top;
  content: "";
}
.news-section.alternate:before {
  display: none;
}

.news-block {
  position: relative;
  margin-bottom: 30px;
}

.news-block .inner-box {
  position: relative;
  background-color: #ffffff;
  border-bottom: 3px solid transparent;
  border-radius: 10px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  -webkit-box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
          box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
}

.news-block .inner-box:hover {
  border-bottom: 3px solid var(--border-theme-color2);
}

.news-block .date {
  position: absolute;
  right: -10px;
  top: 20px;
  background: var(--theme-color2);
  color: #ffffff;
  z-index: 1;
  padding: 5px 20px;
  font-size: 11px;
  font-weight: 700;
  line-height: 16px;
  border-radius: 20px 0 0 20px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.news-block .date:before {
  position: absolute;
  bottom: -10px;
  right: 0;
  border-right: 10px solid transparent;
  border-top: 10px solid var(--theme-color2);
  content: "";
}

.news-block .image-box {
  position: relative;
}

.news-block .image {
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
  border-radius: 10px 10px 0 0;
}

.news-block .image-box .image a:after {
  background: rgba(255, 255, 255, 0.3);
  bottom: 0;
  content: "";
  left: 50%;
  position: absolute;
  right: 51%;
  top: 0;
  opacity: 1;
  pointer-events: none;
  -webkit-transition: all 400ms linear;
  transition: all 400ms linear;
}

.news-block .inner-box:hover .image-box .image a:after {
  left: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 400ms linear;
  transition: all 400ms linear;
}

.news-block .image img {
  display: block;
  border-radius: 10px 10px 0 0;
  width: 100%;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
}

.news-block .inner-box:hover .image img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.news-block .lower-content {
  position: relative;
  padding: 30px 40px;
}

.news-block .post-info {
  position: relative;
  background-color: #ffffff;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.news-block .post-info li {
  position: relative;
  font-size: 14px;
  line-height: 25px;
  color: #819291;
  font-weight: 400;
  margin-right: 15px;
  margin-bottom: 10px;
}

.news-block .post-info li i {
  margin-right: 5px;
  color: var(--theme-color3);
  font-size: 14px;
}

.news-block h4 {
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 1.4em;
  color: var(--theme-color1);
  font-weight: 700;
}

.news-block h4 a {
  color: var(--theme-color1);
  display: inline-block;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.news-block h4 a:hover {
  color: var(--theme-color2);
}

.news-block .theme-btn {
  width: 100%;
  text-transform: uppercase;
}

.news-block .author {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 32px;
  margin-top: 25px;
}

.news-block .author .thumb {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 0;
  margin-right: 10px;
}

.news-block .author .name {
  font-size: 14px;
  line-height: 1em;
  color: #819291;
  font-weight: 600;
}

/*** 

====================================================================
    Clients Section
====================================================================

***/
.clients-section {
  position: relative;
  background-color: #f6f5f0;
  z-index: 1;
}

.clients-section .icon-leaf {
  position: absolute;
  background-size: cover;
}
.clients-section .icon-leaf.leaf-1 {
  left: -150px;
  bottom: 50px;
  height: 50px;
  width: 46px;
  -webkit-animation: fa-spin 10s infinite linear;
          animation: fa-spin 10s infinite linear;
}
.clients-section .icon-leaf.leaf-2 {
  left: -280px;
  top: 20px;
  height: 76px;
  width: 70px;
  -webkit-animation: fa-spin 20s infinite linear;
          animation: fa-spin 20s infinite linear;
}
.clients-section .icon-leaf.leaf-3 {
  right: -260px;
  top: -45px;
  height: 121px;
  width: 112px;
  -webkit-animation: fa-spin 30s infinite linear;
          animation: fa-spin 30s infinite linear;
}

.sponsors-outer {
  position: relative;
  padding: 60px 0;
}

.clients-carousel .slide-item {
  position: relative;
  overflow: hidden;
  text-align: center;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.clients-carousel .slide-item a {
  position: relative;
}

.clients-carousel .slide-item img {
  display: inline-block;
  max-width: 100%;
  width: auto;
  height: auto;
  opacity: 0.2;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.clients-carousel .slide-item:hover img {
  background-color: #ffffff;
  opacity: 1;
}

.clients-carousel .owl-nav,
.clients-carousel .owl-dots {
  display: none;
}

.clients-section.style-two {
  background-color: #ffffff;
}
.clients-section.style-two .icon-leaf.leaf-1 {
  left: -130px;
  top: -50px;
  height: 105px;
  width: 97px;
  opacity: 0.5;
  -webkit-animation: fa-spin 10s infinite linear;
          animation: fa-spin 10s infinite linear;
}

.clients-section.style-two .sponsors-outer {
  border-top: 1px solid #e8e6dd;
}

/***

====================================================================
    team Section
====================================================================

***/
.team-section {
  position: relative;
  padding: 120px 0 90px;
  overflow: hidden;
}
.team-section .icon-dots {
  right: -230px;
  bottom: 25px;
}
.team-section .icon-lines {
  left: -370px;
  bottom: 140px;
}

.team-block {
  position: relative;
  margin-bottom: 30px;
}
.team-block .inner-box {
  position: relative;
  padding: 0 0 75px;
}
.team-block .inner-box:hover .image-box:after {
  height: 100%;
  opacity: 0;
  -webkit-transition: all 400ms linear;
  transition: all 400ms linear;
}
.team-block .inner-box:hover .info-box {
  -webkit-box-shadow: 0 10px 60px rgba(0, 0, 0, 0.07);
          box-shadow: 0 10px 60px rgba(0, 0, 0, 0.07);
  padding-bottom: 60px;
}
.team-block .inner-box:hover .social-links {
  bottom: 22px;
  opacity: 1;
  visibility: visible;
}
.team-block .inner-box:hover .image-box .image img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.team-block .image-box {
  position: relative;
}
.team-block .image-box .image {
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
}
.team-block .image-box .image img {
  width: 100%;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
}
.team-block .image-box:after {
  background: rgba(255, 255, 255, 0.3);
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 0;
  opacity: 1;
  z-index: 9;
  pointer-events: none;
}
.team-block .info-box {
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 0;
  padding: 28px 25px;
  text-align: center;
  background-color: #ffffff;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.team-block .info-box .name {
  display: block;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 2px;
}
.team-block .info-box .name:hover {
  color: var(--theme-color2);
}
.team-block .info-box .designation {
  font-size: 14px;
  line-height: 26px;
  color: #767676;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.team-block .social-links {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
}
.team-block .social-links a {
  position: relative;
  display: block;
  height: 30px;
  width: 30px;
  margin: 0 10px;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: #7b8391;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.team-block .social-links a:hover {
  color: var(--theme-color2);
}

/***

====================================================================
    team Section
====================================================================

***/
.team-section-two {
  position: relative;
  padding: 120px 0 80px;
  overflow: hidden;
}
.team-section-two::before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center top;
  margin-top: -375px;
  min-height: 200%;
  content: "";
}

.team-block-two {
  position: relative;
  margin-bottom: 40px;
}
.team-block-two .inner-box {
  position: relative;
  padding: 25px 15px 0;
}
.team-block-two .inner-box:before {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 120px;
  width: 100%;
  background-color: var(--bg-theme-color3);
  content: "";
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
}
.team-block-two .inner-box:hover .image img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-transform-origin: top center;
          transform-origin: top center;
}
.team-block-two .inner-box:hover:before {
  background-color: var(--bg-theme-color1);
}
.team-block-two .inner-box:hover .social-links {
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
  opacity: 1;
  visibility: visible;
}
.team-block-two .inner-box:hover .name {
  color: #ffffff;
}
.team-block-two .inner-box:hover .designation {
  color: #8c8f94;
}
.team-block-two .image-box {
  position: relative;
}
.team-block-two .image-box .image {
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
}
.team-block-two .image-box .image img {
  width: 100%;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
}
.team-block-two .info-box {
  position: relative;
  padding: 0 15px;
  margin-bottom: 25px;
}
.team-block-two .info-box .name {
  margin-bottom: 5px;
}
.team-block-two .info-box .designation {
  display: block;
  font-size: 12px;
  font-weight: 500;
  color: #767676;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 1em;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
}
.team-block-two .share-icon {
  position: absolute;
  right: 20px;
  bottom: -45px;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  color: var(--theme-color1);
  background-color: var(--bg-theme-color2);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  z-index: 1;
}
.team-block-two .social-links {
  position: absolute;
  right: 20px;
  top: 100%;
  margin-top: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: var(--bg-theme-color2);
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
  -webkit-transform-origin: top;
          transform-origin: top;
  z-index: 3;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
}
.team-block-two .social-links a {
  position: relative;
  height: 40px;
  width: 40px;
  display: block;
  font-size: 14px;
  line-height: 40px;
  text-align: center;
  color: var(--theme-color1);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.team-block-two .social-links a:hover {
  color: #ffffff;
  background-color: var(--bg-theme-color1);
}

/***

====================================================================
    Products details
====================================================================

***/
.product-details .bxslider .image-box {
  position: relative;
  display: block;
  margin-right: 30px;
  margin-bottom: 10px;
}
.product-details .bxslider .image-box img {
  width: 100%;
}
.product-details .bxslider .thumb-box li {
  position: relative;
  display: inline-block;
  float: left;
  margin-right: 10px;
  margin-bottom: 15px;
  width: 100px;
  height: 100px;
}
.product-details .bxslider .thumb-box li:last-child {
  margin: 0px !important;
}
.product-details .bxslider .thumb-box li a {
  position: relative;
  display: inline-block;
}
.product-details .bxslider .thumb-box li a:before {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.product-details .bxslider .thumb-box li a.active:before {
  opacity: 1;
}
.product-details .bx-wrapper {
  margin-bottom: 30px;
}
.product-details .product-info .product-details__top {
  position: relative;
  display: block;
  margin-top: -8px;
}
.product-details .product-info .product-details__title {
  font-size: 40px;
  line-height: 44px;
  font-weight: 700;
  margin: 0;
}
.product-details .product-info .product-details__title span {
  position: relative;
  display: inline-block;
  color: var(--theme-color1);
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
  margin-left: 20px;
  letter-spacing: 0;
}
.product-details .product-info .product-details__reveiw {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 22px;
  padding-bottom: 20px;
  margin-bottom: 31px;
  border-bottom: 1px solid #e0e4e8;
}
.product-details .product-info .product-details__reveiw i {
  font-size: 16px;
  color: var(--theme-color2);
}
.product-details .product-info .product-details__reveiw i + i {
  margin-left: 4px;
}
.product-details .product-info .product-details__reveiw span {
  position: relative;
  top: 1px;
  line-height: 1;
  font-size: 16px;
  color: var(--theme-color1);
  margin-left: 18px;
}
.product-details .product-info .product-details__quantity-title {
  margin: 0;
  color: #222;
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  margin-right: 20px;
}
.product-details .product-info .product-details__buttons {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 30px;
  margin-top: 40px;
}
@media only screen and (max-width: 767px) {
  .product-details .product-info .product-details__buttons {
    display: block;
  }
}
.product-details .product-info .product-details__buttons-1 {
  position: relative;
  display: block;
}
@media only screen and (max-width: 767px) {
  .product-details .product-info .product-details__buttons-1 {
    margin-bottom: 10px;
    margin-right: 10px;
  }
}
.product-details .product-info .product-details__buttons-2 {
  position: relative;
  display: block;
  margin-left: 10px;
}
.product-details .product-info .product-details__buttons-2 .thm-btn {
  background-color: var(--theme-color2);
}
.product-details .product-info .product-details__buttons-2 .thm-btn:before {
  background-color: var(--theme-color1);
}
.product-details .product-info .product-details__buttons-2 .thm-btn:after {
  background-color: var(--theme-color1);
}
@media only screen and (max-width: 767px) {
  .product-details .product-info .product-details__buttons-2 {
    margin-left: 0;
    margin-top: 0;
  }
}
.product-details .product-info .product-details__social {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.product-details .product-info .product-details__social .title {
  position: relative;
  display: block;
}
.product-details .product-info .product-details__social .title h3 {
  color: #222;
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
}
.product-details .product-info .product-details__social .social-icon-one {
  margin-left: 30px;
}
@media only screen and (max-width: 767px) {
  .product-details .product-info .product-details__social {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: start;
  }
  .product-details .product-info .product-details__social .social-icon-one {
    margin-left: 0;
  }
}

.product-details__quantity {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 20px;
}
.product-details__quantity .quantity-box {
  position: relative;
  width: 98px;
  border-radius: 10px;
  height: 60px;
}
.product-details__quantity .quantity-box input {
  width: 98px;
  border-radius: 10px;
  height: 60px;
  border: 1px solid #e0e4e8;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  padding-left: 30px;
  outline: none;
  font-size: 18px;
  font-weight: 500;
  color: var(--theme-color1);
}
.product-details__quantity .quantity-box button {
  width: 29px;
  height: 29px;
  background-color: transparent;
  color: var(--text-color-bg-theme-color1);
  font-size: 8px;
  position: absolute;
  top: 1px;
  right: 1px;
  background-color: var(--theme-color1);
  border: none;
  border-left: 1px solid #e0e4e8;
  border-top-right-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  outline: none;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.product-details__quantity .quantity-box button:hover {
  color: var(--text-color-bg-theme-color2);
  background-color: var(--theme-color2);
}
.product-details__quantity .quantity-box button.sub {
  bottom: 1px;
  top: auto;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 10px;
}

.product-discription {
  position: relative;
  display: block;
}
.product-discription .product-description__title {
  font-size: 30px;
  margin-bottom: 27px;
}
.product-discription .product-description__text1 {
  font-size: 16px;
  line-height: 30px;
  margin: 0;
}
.product-discription .product-description__list {
  position: relative;
  display: block;
  margin-top: 30px;
  margin-bottom: 30px;
}
.product-discription .product-description__list ul {
  position: relative;
  display: block;
}
.product-discription .product-description__list ul li {
  position: relative;
  display: block;
  margin-bottom: 2px;
}
.product-discription .product-description__list ul li:last-child {
  margin-bottom: 0px;
}
.product-discription .product-description__list ul li p {
  margin: 0;
  font-weight: 600;
  color: var(--headings-color);
}
.product-discription .product-description__list ul li p span:before {
  position: relative;
  display: inline-block;
  color: var(--theme-color1);
  font-size: 17px;
  line-height: 17px;
  margin-right: 11px;
  top: 2px;
}
.product-discription .tabs-content .text p {
  margin-bottom: 17px;
}
.product-discription .tabs-content .text p:last-child {
  margin-bottom: 0px;
}
.product-discription .tab-btn-box {
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 60px;
}
.product-discription .tab-btn-box:before {
  position: absolute;
  content: "";
  background-color: #e1e8e4;
  width: 100%;
  height: 1px;
  left: 0px;
  top: 28px;
}
.product-discription .tab-btn-box .tab-btns li {
  position: relative;
  display: inline-block;
  font-size: 14px;
  text-transform: uppercase;
  color: #1e2434;
  text-align: center;
  padding: 14px 30px;
  background-color: #fff;
  border: 1px solid #e1e8e4;
  cursor: pointer;
  margin: 0px 8.5px;
  margin-bottom: 15px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.product-discription .tab-btn-box .tab-btns li.active-btn {
  color: var(--text-color-bg-theme-color1);
  background-color: var(--theme-color1);
}
.product-discription .tab-btn-box .tab-btns li:last-child {
  margin-bottom: 0;
}
.product-discription .single-comment-box .inner-box {
  position: relative;
  display: block;
  background-color: #f4f5f4;
  padding: 34px 30px 34px 125px;
}
.product-discription .single-comment-box .inner-box .comment-thumb {
  position: absolute;
  left: 30px;
  top: 40px;
  border-radius: 50%;
  width: 80px;
}
.product-discription .single-comment-box .inner-box .comment-thumb img {
  width: 100%;
  border-radius: 50%;
}
.product-discription .single-comment-box .inner-box .rating {
  position: relative;
  display: block;
  margin-bottom: 2px;
}
.product-discription .single-comment-box .inner-box .rating li {
  position: relative;
  display: inline-block;
  font-size: 12px;
  float: left;
  margin-right: 4px;
  color: #fdc009;
}
.product-discription .single-comment-box .inner-box .rating li:last-child {
  margin: 0px !important;
}
.product-discription .single-comment-box .inner-box h5 {
  display: block;
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 16px;
}
.product-discription .single-comment-box .inner-box h5 span {
  font-weight: 400;
  text-transform: capitalize;
}
.product-discription .customer-comment {
  position: relative;
  display: block;
  margin-bottom: 60px;
}
.product-discription .comment-box {
  position: relative;
  display: block;
  background-color: #fff;
  padding: 51px 60px 60px 60px;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
}
.product-discription .comment-box h3 {
  display: block;
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 23px;
}
.product-discription .comment-box .form-group {
  position: relative;
  margin-bottom: 15px;
}
.product-discription .comment-box .form-group label {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 28px;
  color: #707582;
  margin-bottom: 8px;
}
.product-discription .comment-box .column:last-child .form-group {
  margin-bottom: 0px;
}
.product-discription .comment-box .review-box {
  position: relative;
  display: block;
  margin-top: 8px;
}
.product-discription .comment-box .review-box p {
  position: relative;
  float: left;
  margin-right: 10px;
}
.product-discription .comment-box .review-box .rating {
  position: relative;
  float: left;
}
.product-discription .comment-box .review-box .rating li {
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 28px;
  float: left;
  margin-right: 4px;
  color: #fdc009;
}
.product-discription .comment-box .review-box .rating li:last-child {
  margin: 0px !important;
}
.product-discription .comment-box .custom-controls-stacked {
  position: relative;
  float: left;
}

.related-product h3 {
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .product-details__img {
    margin-bottom: 50px;
  }
  .product-details__title span {
    margin-left: 0;
    display: block;
  }
  .product-details__buttons {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
  }
  .product-details__buttons-2 {
    margin-left: 0;
    margin-top: 10px;
  }
  .product-details__social-link {
    margin-left: 0;
    margin-top: 20px;
  }
}
/***

====================================================================
 Categories Section
====================================================================

***/
.categories-section {
  position: relative;
  padding: 100px 0 70px;
}
.categories-section .bg-pattern {
  position: absolute;
  left: 0;
  top: -220px;
  width: 100%;
  height: 100%;
  content: "";
  background-repeat: no-repeat;
  background-position: left top;
  z-index: -1;
}
.categories-section:before {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 300px;
  width: 100%;
  background: var(--theme-color3);
  content: "";
  z-index: 1;
}
.categories-section:after {
  position: absolute;
  left: 0;
  bottom: -50px;
  height: 70px;
  width: 100%;
  z-index: 2;
  content: "";
  background-repeat: no-repeat;
  background-position: center bottom;
}

.category-block {
  position: relative;
  margin-bottom: 30px;
  z-index: 9;
}
.category-block .inner-box {
  position: relative;
  text-align: center;
  background: #ffffff;
  padding: 40px 30px 30px;
  border-radius: 10px;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.category-block .inner-box:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 190px;
  background-repeat: no-repeat;
  background-position: center bottom;
  content: "";
}
.category-block .inner-box:hover {
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
.category-block .inner-box:hover .image img {
  -webkit-transform: rotate(10deg) scale(1.2);
  transform: rotate(10deg) scale(1.2);
}

.category-block .image {
  position: relative;
  display: inline-block;
  height: 180px;
  width: 180px;
  overflow: hidden;
  border-radius: 50%;
  margin-bottom: 15px;
}
.category-block .image img {
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.category-block h4 {
  font-size: 20px;
  color: var(--theme-color3);
  font-weight: 700;
  margin-bottom: 10px;
}
.category-block h4 a {
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.category-block h4 a:hover {
  color: var(--theme-color1);
}
.category-block p {
  font-size: 14px;
  color: #797f7d;
  line-height: 26px;
  margin-bottom: 0px;
}

/***

====================================================================
    Categories Section Two
====================================================================

***/
.categories-section-two {
  position: relative;
  padding: 120px 0 90px;
}

.category-block-two {
  position: relative;
  padding-top: 70px;
  margin-bottom: 30px;
  z-index: 9;
}
.category-block-two .inner-box {
  position: relative;
  text-align: center;
  background: #ffffff;
  border-radius: 10px;
  margin: 0 auto;
  padding: 18px;
}
.category-block-two .inner-box:before {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 60px;
  width: 100%;
  border-radius: 10px;
  background: #e3eee5;
  content: "";
}
.category-block-two .inner-box:hover .image:before {
  left: 100%;
  -webkit-transition: all 1000ms ease;
  transition: all 1000ms ease;
}
.category-block-two .inner-box:hover .image img {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}
.category-block-two .inner-box:hover h4 a {
  color: var(--theme-color1);
}
.category-block-two .content {
  position: relative;
  background: #ffffff;
  border-radius: 10px;
  padding: 0 40px 40px;
  z-index: 1;
  -webkit-box-shadow: 0 10px 60px rgba(0, 0, 0, 0.07);
  box-shadow: 0 10px 60px rgba(0, 0, 0, 0.07);
}
.category-block-two .content:before {
  position: absolute;
  top: -88px;
  left: 0;
  width: 180px;
  height: 180px;
  right: 0;
  margin: 0 auto;
  border-radius: 50%;
  background: #e3eee5;
  content: "";
}
.category-block-two .content:after {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  font-size: 30px;
  line-height: 1em;
  color: #e8f3ea;
  height: 15px;
  overflow: hidden;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  content: attr(data-text);
}
.category-block-two .image {
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin-bottom: 25px;
  margin-top: -70px;
}
.category-block-two .image img {
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.category-block-two .image:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 120%;
  width: 100%;
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(rgb(255, 255, 255)));
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ffffff", endColorstr="#00ffffff",GradientType=1 );
  content: "";
  opacity: 0.3;
  left: -100%;
  pointer-events: none;
  z-index: 1;
}
.category-block-two h4 {
  font-size: 22px;
  color: var(--theme-color3);
  font-weight: 700;
  margin-bottom: 15px;
}
.category-block-two h4 a {
  color: var(--theme-color3);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.category-block-two p {
  font-size: 16px;
  color: #797f7d;
  line-height: 26px;
  margin-bottom: 0px;
}
.category-block-two .link {
  position: relative;
  display: inline-block;
  height: 50px;
  width: 50px;
  background: #e8f3ea;
  border-radius: 50%;
  line-height: 50px;
  margin-top: 25px;
  color: #608174;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.category-block-two .link:hover {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}
.category-block-two.child-two .link {
  background: #f0e2e3;
}
.category-block-two.child-two .inner-box:before {
  background: #f0e2e3;
}
.category-block-two.child-two .inner-box:hover h4 a {
  color: #e69da2;
}
.category-block-two.child-two .content:before {
  background: #f0e2e3;
}
.category-block-two.child-two .content:after {
  color: #f0e2e3;
}
.category-block-two.child-three .link {
  background: #f1ede1;
}
.category-block-two.child-three .inner-box:before {
  background: #f1ede1;
}
.category-block-two.child-three .inner-box:hover h4 a {
  color: #c9b579;
}
.category-block-two.child-three .content:before {
  background: #f1ede1;
}
.category-block-two.child-three .content:after {
  color: #f1ede1;
}

/***

====================================================================
    Products Section
====================================================================

***/
/*=== Mixitup Gallery ===*/
.featured-products .filters {
  margin-bottom: 40px;
  text-align: center;
}

.featured-products .filters .filter-tabs {
  position: relative;
  display: inline-block;
}

.featured-products .filters li {
  position: relative;
  display: inline-block;
  line-height: 24px;
  padding: 0px 2px 10px;
  cursor: pointer;
  color: #797f7d;
  font-weight: 500;
  font-size: 18px;
  margin: 0 12px 0;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.featured-products .filters li:last-child {
  margin-right: 0;
}

.featured-products .filters .filter.active,
.featured-products .filters .filter:hover {
  color: var(--theme-color3);
}

.featured-products .filters li:before {
  position: absolute;
  left: 0;
  bottom: 8px;
  height: 2px;
  width: 100%;
  content: "";
  background-color: #ffc737;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: top right;
  transform-origin: top right;
  -webkit-transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition: -webkit-transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  transition: -webkit-transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
  transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1), -webkit-transform 500ms cubic-bezier(0.86, 0, 0.07, 1); /* easeInOutQuint */
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1); /* easeInOutQuint */
}

.featured-products .filters li.active:before,
.featured-products .filters li:hover:before {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
}

.products-section {
  position: relative;
  padding: 120px 0 90px;
}
.products-section.alternate:before {
  position: absolute;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 301px;
  width: 532px;
  content: "";
}

.product-block {
  position: relative;
  margin-bottom: 30px;
}

.product-block.mix {
  display: none;
}

.product-block .inner-box {
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.product-block .inner-box {
  position: relative;
  text-align: center;
}
.product-block .inner-box:hover .image:before {
  height: 100%;
  bottom: 0;
}
.product-block .inner-box:hover .image:after {
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 200ms;
          transition-delay: 200ms;
}
.product-block .inner-box:hover .icon-box {
  bottom: 35px;
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 300ms;
          transition-delay: 300ms;
}
.product-block .inner-box:hover .image img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.product-block .sale-badge {
  position: absolute;
  left: -40px;
  top: -40px;
  height: 75px;
  width: 75px;
  background: var(--bg-theme-color2);
  color: #fff;
  z-index: 2;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  line-height: 1em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  padding: 5px 5px;
  font-weight: 700;
  font-size: 12px;
}

.product-block .image-box {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.product-block .image {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow: hidden;
  margin-bottom: 0px;
}
.product-block .image a {
  display: block;
  width: 100%;
}
.product-block .image:before {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 0;
  width: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(var(--bg-theme-color1)));
  background: linear-gradient(to bottom, transparent 0%, var(--bg-theme-color1) 100%);
  content: "";
  pointer-events: none;
  z-index: 1;
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
}
.product-block .image:after {
  position: absolute;
  left: 15px;
  bottom: 15px;
  right: 15px;
  top: 15px;
  border: 2px solid var(--border-theme-color2);
  content: "";
  pointer-events: none;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  border-radius: 10px;
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}
.product-block .image img {
  width: 100%;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.product-block .icon-box {
  position: absolute;
  left: 0;
  bottom: 50px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  z-index: 9;
}

.product-block .ui-btn {
  position: relative;
  display: block;
  height: 55px;
  width: 55px;
  font-size: 18px;
  line-height: 55px;
  text-align: center;
  color: #fff;
  z-index: 9;
  background-color: var(--bg-theme-color2);
  cursor: pointer;
  margin: 0 5px;
  border-radius: 50px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.product-block .ui-btn:hover {
  background-color: #ffffff;
  color: var(--bg-theme-color1);
}

.product-block .content {
  position: relative;
  padding: 25px 30px 0;
}
.product-block .content h4 {
  display: block;
  font-size: 20px;
  color: var(--theme-color1);
  font-weight: 700;
  margin-bottom: 5px;
}
.product-block .content h4 a {
  color: var(--theme-color1);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.product-block .content h4 a:hover {
  color: var(--theme-color2);
}
.product-block .content .rating {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  letter-spacing: 4px;
  font-size: 12px;
  color: var(--bg-theme-color3);
  margin-bottom: 3px;
}
.product-block .content .price {
  display: block;
  font-size: 18px;
  line-height: 28px;
  color: #819291;
  font-weight: 600;
}
.product-block .content .price del {
  display: inline-block;
  margin-left: 15px;
  font-size: 16px;
  color: #ff0000;
  line-height: 27px;
  opacity: 0.3;
}

/*
 * shop-catalog-layouts.scss
 * -----------------------------------------------
*/
table.tbl-shopping-cart .product-thumbnail, table.cart-total .product-thumbnail {
  min-width: 64px;
}
table.tbl-shopping-cart img, table.cart-total img {
  width: 64px;
  -webkit-box-shadow: none;
          box-shadow: none;
}
table.tbl-shopping-cart th,
table.tbl-shopping-cart td, table.cart-total th,
table.cart-total td {
  vertical-align: middle;
  border-left: 1px solid #e3e3e3;
  padding: 20px 30px;
}
table.tbl-shopping-cart .product-name a, table.cart-total .product-name a {
  color: var(--headings-color);
}
table.tbl-shopping-cart .product-name .variation, table.cart-total .product-name .variation {
  font-size: 0.9rem;
  list-style: none;
}
table.tbl-shopping-cart .product-remove a, table.cart-total .product-remove a {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 24px;
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
  background-color: #757575;
  color: #ffffff;
  text-align: center;
}
table.tbl-shopping-cart .coupon-form .apply-button, table.cart-total .coupon-form .apply-button {
  position: relative;
  display: inline-block;
  color: #1e2434;
  background: #f4f5f4;
  padding: 15px 29px;
  cursor: pointer;
  text-transform: uppercase;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
table.tbl-shopping-cart .coupon-form .apply-button:hover, table.cart-total .coupon-form .apply-button:hover {
  color: var(--text-color-bg-theme-color2);
  background-color: var(--theme-color2);
}

table.tbl-shopping-cart > thead > tr > th,
table.tbl-shopping-cart > tbody > tr > th,
table.tbl-shopping-cart > tfoot > tr > th {
  color: #444;
}

.payment-method .accordion-box .block {
  background: #f4f5f4;
  -webkit-box-shadow: none;
          box-shadow: none;
  margin-bottom: 20px;
}
.payment-method .accordion-box .block .acc-content .payment-info {
  position: relative;
  display: block;
  background-color: #fff;
  padding: 30px 30px 10px 30px;
}
.payment-method .accordion-box .block:last-child {
  margin-bottom: 0px;
}
.payment-method .accordion-box .block .acc-btn {
  padding: 19px 30px 22px 30px;
  background-color: var(--theme-color1);
  color: var(--text-color-bg-theme-color1);
}
.payment-method .accordion-box .block .acc-btn .icon-outer {
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  position: absolute;
  top: 50%;
  right: 30px;
  height: auto;
  /* line-height: 65px; */
  font-size: 28px;
}
.payment-method .accordion-box .block .acc-btn.active .icon-outer {
  color: var(--text-color-bg-theme-color1);
}
.payment-method .payment-method h3 {
  margin-bottom: 32px;
}

.shop-sidebar {
  position: relative;
  display: block;
}
.shop-sidebar .sidebar-search {
  margin-bottom: 30px;
}
.shop-sidebar .sidebar-search .search-form .form-group {
  position: relative;
  margin: 0px;
}
.shop-sidebar .sidebar-search .search-form .form-group input[type=search] {
  position: relative;
  width: 100%;
  height: 52px;
  background-color: var(--theme-light-background);
  border: 1px solid var(--theme-light-background);
  border-radius: 5px;
  color: #646578;
  padding: 10px 60px 10px 20px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.shop-sidebar .sidebar-search .search-form .form-group button {
  position: absolute;
  display: inline-block;
  top: 5px;
  right: 5px;
  width: 42px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  font-size: 18px;
  color: var(--text-color-bg-theme-color2);
  background-color: var(--theme-color2);
  cursor: pointer;
  border-radius: 3px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.shop-sidebar .sidebar-search .search-form .form-group button:hover {
  color: var(--text-color-bg-theme-color1);
  background-color: var(--theme-color1);
}
.shop-sidebar .sidebar-widget {
  position: relative;
  display: block;
  background-color: var(--theme-light-background);
  padding: 35px 30px 37px 30px;
  border-radius: 5px;
  margin-bottom: 30px;
}
.shop-sidebar .sidebar-widget:last-child {
  margin-bottom: 0px;
}
.shop-sidebar .sidebar-widget .widget-title {
  position: relative;
  display: block;
  margin-bottom: 16px;
}
.shop-sidebar .category-widget .category-list li {
  position: relative;
  display: block;
  margin-bottom: 14px;
}
.shop-sidebar .category-widget .category-list li:last-child {
  margin-bottom: 0px;
}
.shop-sidebar .category-widget .category-list li a {
  position: relative;
  display: inline-block;
  color: #646578;
  font-weight: 400;
  padding-left: 20px;
}
.shop-sidebar .category-widget .category-list li a:before {
  position: absolute;
  content: "\f0da";
  font-family: "Font Awesome 5 Free";
  left: 0px;
  top: 0px;
  font-size: 16px;
  font-weight: 600;
  color: var(--theme-color1);
}
.shop-sidebar .category-widget .category-list li a:hover {
  color: var(--theme-color1);
}
.shop-sidebar .price-filters .widget-title {
  margin-bottom: 28px;
}
.shop-sidebar .post-widget {
  padding-bottom: 9px;
}
.shop-sidebar .post-widget .post {
  position: relative;
  padding-left: 90px;
  padding-bottom: 24px;
  margin-bottom: 23px;
  min-height: 108px;
  border-bottom: 1px solid #e1e1e1;
}
.shop-sidebar .post-widget .post:last-child {
  margin-bottom: 0px;
  border-bottom: none;
}
.shop-sidebar .post-widget .post .post-thumb {
  position: absolute;
  left: 0px;
  top: 7px;
  width: 70px;
  height: 70px;
  border: 1px solid #d0d4dd;
  border-radius: 5px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.shop-sidebar .post-widget .post .post-thumb img {
  width: 100%;
  border-radius: 5px;
}
.shop-sidebar .post-widget .post a {
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 26px;
  color: #646578;
  margin-bottom: 7px;
}
.shop-sidebar .post-widget .post .price {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 24px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #0a267a;
}
.shop-sidebar .post-widget .post:hover .post-thumb {
  border-color: var(--theme-color1);
}
.shop-sidebar .post-widget .post:hover a {
  color: var(--theme-color1);
}

.range-slider {
  position: relative;
}
.range-slider .title {
  line-height: 26px;
  position: relative;
  display: inline-block;
  margin-right: 4px;
}
.range-slider .title:before {
  position: absolute;
  content: "$";
  left: -5px;
  top: -19px;
  color: #646578;
  font-size: 18px;
}
.range-slider p {
  position: relative;
  display: inline-block;
  color: #646578;
  margin-right: 10px !important;
}
.range-slider .input {
  color: #646578;
  max-width: 75px;
  font-size: 18px;
  margin-top: 5px;
  position: relative;
  display: inline-block;
}
.range-slider .input input {
  background: none;
  color: #646578;
  font-size: 15px;
  text-align: left;
}
.range-slider .ui-widget.ui-widget-content {
  height: 4px;
  border: none;
  margin-bottom: 14px;
  background-color: #d0d4dd;
  border-radius: 2px;
}
.range-slider .ui-slider .ui-slider-range {
  top: 0px;
  height: 4px;
  background-color: var(--theme-color1);
}
.range-slider .ui-state-default {
  top: -5px;
  width: 14px;
  height: 14px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 0px;
  background-color: var(--theme-color1);
}
.range-slider .ui-state-default:before {
  position: absolute;
  content: "";
  background-color: #ffffff;
  width: 6px;
  height: 6px;
  left: 4px;
  top: 4px;
  border-radius: 50%;
}
.range-slider .ui-widget-content .ui-state-default {
  top: -5px;
  width: 14px;
  height: 14px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 0px;
  background-color: var(--theme-color1);
}
.range-slider .ui-widget-content .ui-state-default:before {
  position: absolute;
  content: "";
  background-color: #ffffff;
  width: 6px;
  height: 6px;
  left: 4px;
  top: 4px;
  border-radius: 50%;
}
.range-slider input[type=submit] {
  position: relative;
  display: block;
  background: var(--theme-color1);
  color: var(--text-color-bg-theme-color1);
  float: right;
  text-align: center;
  border: none;
  font-size: 14px;
  font-weight: 500;
  margin-top: 0;
  text-transform: capitalize;
  cursor: pointer;
  padding: 7px 20px;
  border-radius: 10px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.range-slider input[type=submit]:hover {
  color: var(--text-color-bg-theme-color2);
  background-color: var(--theme-color2);
}


.page-title {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
@media screen and (min-device-width: 320px) and (max-device-width: 768px) {
  .page-title {
    padding: 100px 0 115px;
  }
}
.page-title:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #7394531f;
  opacity: 0.8;
  content: "";
}
.page-title .title {
  font-size: 64px;
  color: #ffffff;
  margin-bottom: 17px;
}
.page-title .text {
  position: relative;
  color: #ffffff;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.05em;
  max-width: 520px;
}

.page-breadcrumb {
  position: relative;
  margin-top: 5px;
}
.page-breadcrumb li {
  position: relative;
  display: inline-block;
  margin-right: 12px;
  padding-right: 13px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.page-breadcrumb li:after {
  position: absolute;
  content: "\f105";
  right: -6px;
  top: 1px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
  color: #ffffff;
}
.page-breadcrumb li:last-child {
  padding-right: 0px;
  margin-right: 0px;
}
.page-breadcrumb li:last-child::after {
  display: none;
}
.page-breadcrumb li a {
  color: var(--theme-color2);
  font-weight: 500;
  text-transform: capitalize;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}
.page-breadcrumb li a:hover {
  color: #ffffff;
}

.page-title.style-two {
  background-position: center center;
}
.page-title.style-two .page-breadcrumb-outer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 10px 0;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  z-index: 8;
}
.page-title.style-two .page-breadcrumb li {
  color: rgb(7, 7, 16);
  font-weight: 600;
}
.page-title.style-two .page-breadcrumb li:after {
  color: rgb(7, 7, 16);
}
.page-title.style-two .page-breadcrumb li a {
  color: rgba(7, 7, 16, 0.6);
}
.page-title.style-two .page-breadcrumb li a:hover {
  color: rgb(7, 7, 16);
}

.play-now {
  position: relative;
  display: block;
  z-index: 9;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.play-now .icon {
  position: relative;
  display: inline-block;
  height: 70px;
  width: 70px;
  text-align: center;
  line-height: 70px;
  background-color: #ffffff;
  color: #ff6d2e;
  z-index: 1;
  padding-left: 5px;
  font-size: 14px;
  display: block;
  border-radius: 50%;
  -webkit-box-shadow: 0 0px 10px 0 rgba(255, 255, 255, 0.3);
  -ms-box-shadow: 0 0px 10px 0 rgba(255, 255, 255, 0.3);
  -o-box-shadow: 0 0px 10px 0 rgba(255, 255, 255, 0.3);
  box-shadow: 0 0px 10px 0 rgba(255, 255, 255, 0.3);
  -webkit-transform-origin: center;
  transform-origin: center;
}
.play-now .ripple {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 70px;
  width: 70px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -ms-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -o-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -webkit-animation: ripple 3s infinite;
  animation: ripple 3s infinite;
}
.play-now .ripple:before {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 70px;
  width: 70px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -ms-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -o-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -webkit-animation: ripple 3s infinite;
  animation: ripple 3s infinite;
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
  content: "";
  position: absolute;
}
.play-now .ripple:after {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 70px;
  width: 70px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -ms-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -o-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -webkit-animation: ripple 3s infinite;
  animation: ripple 3s infinite;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  content: "";
  position: absolute;
}

.background-image {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-repeat: repeat;
  background-position: center;
  background-size: cover;
}

.error-page__inner {
  position: relative;
  display: block;
  text-align: center;
}
.error-page__inner .error-page__title-box {
  position: relative;
  display: block;
}
.error-page__inner .error-page__title {
  position: relative;
  display: inline-block;
  font-size: 280px;
  line-height: 280px;
  margin-bottom: 0;
  color: var(--theme-color1);
}
.error-page__inner .error-page__sub-title {
  font-size: 40px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  line-height: 50px;
  margin-top: -16px;
}
.error-page__inner .error-page__text {
  font-size: 20px;
  line-height: 30px;
  text-align: center;
}
.error-page__inner .error-page__form {
  position: relative;
  display: block;
  margin: 42px auto 20px;
}
.error-page__inner .error-page__form input[type=search] {
  height: 60px;
  width: 100%;
  border: none;
  outline: none;
  background-color: #f5f5f5;
  font-size: 14px;
  color: #333;
  padding-left: 50px;
  padding-right: 75px;
  border-radius: 7px;
}
.error-page__inner .error-page__form button[type=submit] {
  background-color: transparent;
  font-size: 22px;
  position: absolute;
  top: 0;
  right: 0px;
  bottom: 0;
  width: 72px;
  outline: none;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
}
.error-page__inner .error-page__form-input {
  position: relative;
  display: block;
  max-width: 570px;
  width: 100%;
  margin: 0 auto;
}

/***
=============================================
    Services Details
=============================================
***/
.service-sidebar {
  position: relative;
  display: block;
  max-width: 365px;
  width: 100%;
}
.service-sidebar .service-sidebar-single {
  position: relative;
  display: block;
  margin-bottom: 30px;
}
.service-sidebar .service-sidebar-single-services {
  position: relative;
  display: block;
  background: #f6f4ec;
  padding: 35px 30px 25px;
}
.service-sidebar .service-sidebar-single-services .title {
  position: relative;
  display: block;
  margin-bottom: 12px;
  padding-left: 20px;
}
.service-sidebar .service-sidebar-single-services .title h3 {
  color: var(--headings-color);
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.02em;
}
.service-sidebar .service-sidebar-single-services ul {
  position: relative;
  display: block;
  margin-top: 10px;
}
.service-sidebar .service-sidebar-single-services ul li {
  position: relative;
  display: block;
  margin-bottom: 5px;
  margin-top: -10px;
}
.service-sidebar .service-sidebar-single-services ul li:last-child {
  margin-bottom: 0;
}
.service-sidebar .service-sidebar-single-services ul li a {
  position: relative;
  display: block;
  color: #687469;
  font-size: 18px;
  padding: 22px 20px 22px;
  background: transparent;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.service-sidebar .service-sidebar-single-services ul li a:hover {
  color: var(--headings-color);
  border-left: 5px solid #222;
}
.service-sidebar .service-sidebar-single-services ul li a:hover::before {
  opacity: 1;
  -webkit-transform: perspective(400px) rotateX(0deg);
          transform: perspective(400px) rotateX(0deg);
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
}
.service-sidebar .service-sidebar-single-services ul li a:hover i {
  color: var(--theme-color1);
}
.service-sidebar .service-sidebar-single-services ul li a::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: "";
  z-index: -1;
  opacity: 1;
  -webkit-transform: perspective(400px) rotateX(90deg);
          transform: perspective(400px) rotateX(90deg);
  -webkit-transform-origin: bottom;
          transform-origin: bottom;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
  background: #ffffff;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
}
.service-sidebar .service-sidebar-single-services ul li a i {
  font-size: 16px;
}
.service-sidebar .service-sidebar-single-services ul li.current a::before {
  opacity: 1;
  -webkit-transform: perspective(400px) rotateX(0deg);
          transform: perspective(400px) rotateX(0deg);
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
}
.service-sidebar .service-sidebar-single-services ul li.current a i {
  color: var(--theme-color1);
}
.service-sidebar .service-sidebar-single-services ul li.current:first-child {
  margin-top: 20px;
}
.service-sidebar .service-sidebar-single-services ul li.current:last-child {
  margin-bottom: 35px;
}
.service-sidebar .service-sidebar-single-contact-box {
  position: relative;
  display: block;
  background-attachment: scroll;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 50px 0px 44px;
  z-index: 1;
}
.service-sidebar .service-sidebar-single-contact-box::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(109, 140, 84, 0.93);
  content: "";
  z-index: -1;
}
.service-sidebar .service-sidebar-single-contact-box .icon {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 60px;
  text-align: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  border-radius: 50%;
  background: var(--theme-color2);
}
.service-sidebar .service-sidebar-single-contact-box .icon:hover {
  background-color: var(--headings-color);
}
.service-sidebar .service-sidebar-single-contact-box .icon:hover span::before {
  color: #fff;
}
.service-sidebar .service-sidebar-single-contact-box .icon span::before {
  position: relative;
  display: inline-block;
  color: var(--headings-color);
  font-size: 30px;
  line-height: 60px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.service-sidebar .service-sidebar-single-contact-box .title {
  position: relative;
  display: block;
  margin-top: 20px;
  margin-bottom: 42px;
}
.service-sidebar .service-sidebar-single-contact-box .title h2 {
  color: #ffffff;
  font-size: 36px;
}
.service-sidebar .service-sidebar-single-contact-box .phone {
  font-size: 24px;
  line-height: 34px;
}
.service-sidebar .service-sidebar-single-contact-box .phone a {
  color: #ffffff;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.service-sidebar .service-sidebar-single-contact-box .phone a:hover {
  color: var(--theme-color1);
}
.service-sidebar .service-sidebar-single-contact-box p {
  color: #ffffff;
  font-size: 14px;
  line-height: 22px;
}
.service-sidebar .service-sidebar-single-btn {
  position: relative;
  display: block;
}
.service-sidebar .service-sidebar-single-btn .thm-btn {
  font-size: 16px;
  padding: 13px 50px 28px;
}
.service-sidebar .service-sidebar-single-btn .thm-btn span::before {
  position: relative;
  display: inline-block;
  top: 13px;
  color: #334b35;
  font-size: 40px;
  padding-right: 25px;
  -webkit-transition: all 600ms ease;
  transition: all 600ms ease;
  font-weight: 500;
}
.service-sidebar .service-sidebar-single-btn .thm-btn:hover span::before {
  color: #ffffff;
}
.service-sidebar .banner-widget {
  position: relative;
  display: block;
  background: #fff;
  overflow: hidden;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.08);
          box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.08);
}
.service-sidebar .banner-widget .widget-content {
  position: relative;
  display: block;
  width: 100%;
  padding: 45px 30px 40px 30px;
  background-size: cover;
  background-repeat: no-repeat;
}
.service-sidebar .banner-widget .widget-content .shape {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 278px;
  background-repeat: no-repeat;
  background-size: cover;
}
.service-sidebar .banner-widget .content-box {
  position: relative;
  max-width: 200px;
  width: 100%;
}
.service-sidebar .banner-widget .content-box .icon-box {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 40px;
  background-color: var(--theme-color2);
  color: var(--text-color-bg-theme-color2);
  text-align: center;
  margin-bottom: 23px;
}
.service-sidebar .banner-widget .content-box .icon-box .icon-shape {
  position: absolute;
  top: -15px;
  right: -38px;
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
}
.service-sidebar .banner-widget .content-box h3 {
  display: block;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 22px;
}
.service-sidebar .banner-widget .content-box .theme-btn-two:hover {
  background: #0a267a;
}
.service-sidebar .service-sidebar-single-btn .theme-btn {
  padding: 20px 50px;
}
.service-sidebar .service-sidebar-single-btn .theme-btn .btn-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.service-sidebar .service-sidebar-single-btn .theme-btn span::before {
  position: relative;
  display: inline-block;
  font-size: 36px;
  padding-right: 25px;
  margin-top: 7px;
  -webkit-transition: all 600ms ease;
  transition: all 600ms ease;
  font-weight: 500;
}

.services-details__content .feature-list .single-item {
  position: relative;
  display: block;
  border: 1px solid #e1e8e4;
  padding: 16px 30px 16px 53px;
  margin-bottom: 20px;
  -webkit-transition: all 600ms ease;
  transition: all 600ms ease;
}
.services-details__content .feature-list .single-item .icon-box {
  color: var(--theme-color1);
  position: absolute;
  left: 20px;
  top: 16px;
  font-size: 18px;
  -webkit-transition: all 600ms ease;
  transition: all 600ms ease;
}
.services-details__content .feature-list .single-item .title {
  display: block;
  margin: 0;
  font-size: 16px;
  line-height: 32px;
  font-weight: 600;
  text-transform: uppercase;
}
.services-details__content .feature-list .single-item:hover {
  background-color: var(--theme-color2);
  color: var(--text-color-bg-theme-color2);
}
.services-details__content .feature-list .single-item:hover .title {
  color: var(--text-color-bg-theme-color2);
}
.services-details__content .feature-list .single-item:hover .icon-box {
  color: var(--text-color-bg-theme-color2);
}

.service-list li {
  position: relative;
  display: block;
  margin-bottom: 10px;
}
.service-list li:last-child {
  margin-bottom: 0px;
}
.service-list li a {
  position: relative;
  display: block;
  font-size: 18px;
  color: var(--headings-color);
  font-weight: 600;
  background-color: #fff;
  padding: 17px 20px 17px 50px;
  -webkit-box-shadow: 20px 5px 20px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 20px 5px 20px 0px rgba(0, 0, 0, 0.05);
}
.service-list li a:hover, .service-list li a.current {
  color: #fff;
  background-color: var(--theme-color1);
  padding-left: 80px;
}
.service-list li a:hover i, .service-list li a.current i {
  width: 60px;
  color: #fff;
  background-color: var(--theme-color2);
}
.service-list li i {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  left: 0px;
  top: 0px;
  width: 30px;
  height: 100%;
  background-color: #f6f4ec;
  text-align: center;
  font-size: 16px;
  color: #707582;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

/*--------------------------------------------------------------
# Project Details
--------------------------------------------------------------*/
.project-details__img {
  position: relative;
  display: block;
}
.project-details__img img {
  width: 100%;
  border-radius: 10px;
}

.project-details__content {
  position: relative;
  display: block;
}

.project-details__content-left {
  position: relative;
  display: block;
  margin-top: 31px;
}

.project-details__content-right {
  position: relative;
  display: block;
  margin-top: 40px;
}

.project-details__details-box {
  position: relative;
  border-radius: 10px;
  background-color: #f5f5f5;
  padding: 43px 50px 50px;
  z-index: 1;
}

.project-details__details-list {
  position: relative;
  display: block;
}
.project-details__details-list li {
  position: relative;
  display: block;
}
.project-details__details-list li + li {
  margin-top: 24px;
}

.project-details__client {
  font-size: 16px;
  color: #838d9e;
  line-height: 24px;
  margin: 0;
}

.project-details__name {
  font-size: 16px;
  line-height: 24px;
}

.project-details__social {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.project-details__social a {
  position: relative;
  height: 40px;
  width: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  background-color: var(--theme-color1);
  color: var(--text-color-bg-theme-color1);
  font-size: 15px;
  border-radius: 50%;
  overflow: hidden;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 1;
}
.project-details__social a:hover {
  background-color: var(--theme-color2);
  color: var(--text-color-bg-theme-color2);
}
.project-details__social a + a {
  margin-left: 10px;
}

.project-details__pagination-box {
  position: relative;
  display: block;
  text-align: center;
  border-top: 1px solid #ece9e0;
  border-bottom: 1px solid #ece9e0;
  padding: 30px 0;
  margin-top: 117px;
}

.project-details__pagination {
  position: relative;
  display: block;
}
.project-details__pagination li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.project-details__pagination li a {
  font-size: 14px;
  color: #757873;
  font-weight: 400;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.project-details__pagination li a:hover {
  color: var(--theme-color1);
}
.project-details__pagination li a:hover i {
  background-color: var(--theme-color2);
  color: var(--text-color-bg-theme-color2);
  border: 2px solid var(--theme-color2);
}
.project-details__pagination li .content {
  position: relative;
  display: block;
}
.project-details__pagination li.next {
  float: left;
  position: relative;
}
.project-details__pagination li.next i {
  position: relative;
  height: 52px;
  width: 52px;
  border: 2px solid #0e2207;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  color: #0e2207;
  font-size: 16px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  margin-right: 20px;
  z-index: 1;
}
.project-details__pagination li.next .content {
  text-align: left;
}
.project-details__pagination li.previous {
  position: relative;
  float: right;
}
.project-details__pagination li.previous i {
  position: relative;
  height: 52px;
  width: 52px;
  border: 2px solid #0e2207;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  color: #0e2207;
  font-size: 16px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  margin-left: 20px;
  z-index: 1;
}
.project-details__pagination li.previous .content {
  text-align: right;
}

/***

====================================================================
    Blog Details
====================================================================

***/
.blog-details {
  position: relative;
  display: block;
}

.blog-details__left {
  position: relative;
  display: block;
}

.blog-details__img {
  position: relative;
  display: block;
  border-radius: 10px;
}
.blog-details__img img {
  width: 100%;
  border-radius: 10px;
}

.blog-details__date {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: var(--theme-color1);
  text-align: center;
  padding: 21px 24px 20px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.blog-details__date .day {
  font-size: 16px;
  color: #fff;
  font-weight: var(--body-font-weight-bold);
  line-height: 16px;
}
.blog-details__date .month {
  position: relative;
  display: block;
  font-size: 10px;
  font-weight: var(--body-font-weight-bold);
  color: #fff;
  line-height: 12px;
  text-transform: uppercase;
}

.blog-details__content {
  position: relative;
  display: block;
  margin-top: 22px;
}

.blog-details__meta {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.blog-details__meta li + li {
  margin-left: 18px;
}
.blog-details__meta li a {
  font-size: 15px;
  color: #777;
  font-weight: 500;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.blog-details__meta li a:hover {
  color: var(--theme-color1);
}
.blog-details__meta li a i {
  color: var(--theme-color1);
  margin-right: 6px;
}

.blog-details__title {
  font-size: 30px;
  line-height: 40px;
  margin-top: 12px;
  margin-bottom: 21px;
  font-weight: var(--body-font-weight-bold);
}

.blog-details__bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 30px 0 30px;
  margin-top: 49px;
  border-top: 1px solid #ece9e0;
}
@media only screen and (max-width: 767px) {
  .blog-details__bottom {
    gap: 30px;
  }
}
.blog-details__bottom p {
  margin: 0;
}

.blog-details__tags span {
  color: #0e2207;
  font-size: 20px;
  margin-right: 14px;
  font-weight: var(--body-font-weight-bold);
}
.blog-details__tags a {
  position: relative;
  font-size: 12px;
  background-color: var(--theme-color2);
  color: var(--text-color-bg-theme-color2);
  display: inline-block;
  padding: 5px 30px 5px;
  font-weight: var(--body-font-weight-bold);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  border-radius: 35px;
}
.blog-details__tags a:hover {
  background-color: var(--theme-color1);
  color: var(--text-color-bg-theme-color1);
  text-decoration: none;
}
.blog-details__tags a + a {
  margin-left: 6px;
}

.blog-details__social-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.blog-details__social-list a {
  position: relative;
  height: 43px;
  width: 43px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  color: var(--theme-light-background-text-color);
  background-color: var(--theme-light-background);
  font-size: 15px;
  border-radius: 50%;
  overflow: hidden;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 1;
}
.blog-details__social-list a:hover {
  color: var(--text-color-bg-theme-color2);
}
.blog-details__social-list a:hover:after {
  opacity: 1;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}
.blog-details__social-list a:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: var(--theme-color2);
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-property: all;
  transition-property: all;
  opacity: 1;
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  z-index: -1;
}
.blog-details__social-list a + a {
  margin-left: 10px;
}

.blog-details__pagenation-box {
  position: relative;
  display: block;
  overflow: hidden;
  margin-bottom: 53px;
}

.blog-details__pagenation {
  position: relative;
  display: block;
}
.blog-details__pagenation li {
  position: relative;
  float: left;
  font-size: 20px;
  color: #0e2207;
  font-weight: var(--body-font-weight-bold);
  color: var(--theme-light-background-text-color);
  background-color: var(--theme-light-background);
  line-height: 30px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  max-width: 370px;
  width: 100%;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 52px;
  padding-bottom: 52px;
  border-radius: 10px;
}
.blog-details__pagenation li:hover {
  background-color: var(--theme-color2);
  color: var(--text-color-bg-theme-color2);
}
.blog-details__pagenation li + li {
  margin-left: 30px;
}

/* Nav Links */
.nav-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 53px;
}
@media only screen and (max-width: 767px) {
  .nav-links {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 20px;
  }
}
.nav-links .prev {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
  width: calc(50% - 15px);
  margin-right: 30px;
}
@media only screen and (max-width: 767px) {
  .nav-links .prev {
    width: 100%;
  }
}
.nav-links .prev .thumb {
  margin-right: 20px;
}
.nav-links .next {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  width: calc(50% - 15px);
}
@media only screen and (max-width: 767px) {
  .nav-links .next {
    width: 100%;
  }
}
.nav-links .next .thumb {
  margin-left: 20px;
}
.nav-links > div {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.nav-links > div .thumb {
  display: inline-block;
  min-width: 60px;
  width: 60px;
  height: 60px;
  overflow: hidden;
}
.nav-links > div .thumb a {
  display: inline-block;
}
.nav-links > div > a {
  display: inline-block;
  word-wrap: break-word;
  white-space: -moz-pre-wrap;
  white-space: pre-wrap;
  font-size: 20px;
  line-height: 1.637;
  font-weight: var(--body-font-weight-bold);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  color: var(--theme-light-background-text-color);
  background-color: var(--theme-light-background);
  padding: 52px 50px;
  border-radius: 10px;
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .nav-links > div > a {
    padding: 30px;
  }
}
.nav-links > div > a:hover {
  color: var(--text-color-bg-theme-color1);
  background-color: var(--theme-color1);
}

/*** 

====================================================================
Sidebar
====================================================================

***/
@media (max-width: 991px) {
  .sidebar {
    margin-top: 50px;
  }
}
.sidebar__single + .sidebar__single {
  margin-top: 30px;
}

.sidebar__title {
  margin: 0;
  font-size: 20px;
  margin-bottom: 5px;
  font-weight: var(--h4-font-weight);
}

.sidebar__search {
  position: relative;
  display: block;
}

.sidebar__search-form {
  position: relative;
}
.sidebar__search-form input[type=search] {
  display: block;
  border: none;
  outline: none;
  background-color: var(--theme-color2);
  color: var(--text-color-bg-theme-color2);
  font-size: 16px;
  font-weight: 500;
  padding-left: 50px;
  height: 74px;
  width: 100%;
  padding-right: 80px;
  border-radius: 10px;
}
.sidebar__search-form input[type=search]::-webkit-input-placeholder {
  color: var(--text-color-bg-theme-color2);
  opacity: 1;
}
.sidebar__search-form input[type=search]::-webkit-input-placeholder, .sidebar__search-form input[type=search]:-ms-input-placeholder, .sidebar__search-form input[type=search]::-ms-input-placeholder, .sidebar__search-form input[type=search]::placeholder {
  color: var(--text-color-bg-theme-color2);
  opacity: 1;
}
.sidebar__search-form button[type=submit] {
  background-color: transparent;
  color: var(--text-color-bg-theme-color2);
  font-size: 22px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 72px;
  outline: none;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
}

.sidebar__post {
  position: relative;
  display: block;
  padding: 46px 30px 30px;
  color: var(--theme-light-background-text-color);
  background-color: var(--theme-light-background);
  border-radius: 10px;
  overflow: hidden;
  z-index: 1;
}
.sidebar__post .sidebar__title {
  margin-left: 20px;
}
.sidebar__post .sidebar__post-list {
  margin: 0;
}
.sidebar__post .sidebar__post-list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 16px 20px 17px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.sidebar__post .sidebar__post-list li:hover {
  background-color: #ffffff;
  border-radius: 10px;
}
.sidebar__post .sidebar__post-list li + li {
  margin-top: 11px;
}
.sidebar__post .sidebar__post-list .sidebar__post-image {
  margin-right: 20px;
  -webkit-box-flex: 70px;
      -ms-flex: 70px 0 0px;
          flex: 70px 0 0;
}
.sidebar__post .sidebar__post-list .sidebar__post-image > img {
  width: 80px;
  border-radius: 10px;
}
.sidebar__post .sidebar__post-list .sidebar__post-content {
  position: relative;
  top: -3px;
}
.sidebar__post .sidebar__post-list .sidebar__post-content h3 {
  font-size: 18px;
  margin: 0;
  line-height: 26px;
  letter-spacing: 0;
}
.sidebar__post .sidebar__post-list .sidebar__post-content h3 a {
  color: #0e2207;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  display: block;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}
.sidebar__post .sidebar__post-list .sidebar__post-content-meta {
  font-size: 14px;
  font-weight: 500;
  color: #757873 !important;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.sidebar__post .sidebar__post-list .sidebar__post-content-meta i {
  color: var(--theme-color2);
  font-size: 14px;
  padding-right: 3px;
}

.sidebar__category {
  position: relative;
  display: block;
  color: var(--theme-light-background-text-color);
  background-color: var(--theme-light-background);
  border-radius: 10px;
  padding: 46px 45px 50px;
  overflow: hidden;
  z-index: 1;
}
.sidebar__category .sidebar__title {
  margin-bottom: 9px;
}

.sidebar__category-list {
  margin: 0;
}
.sidebar__category-list li + li {
  margin-top: 4px;
}
.sidebar__category-list li a {
  color: #757873;
  font-size: 16px;
  position: relative;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  display: block;
  background: none;
  padding: 12px 20px;
  font-weight: 500;
  border-radius: 10px;
}
.sidebar__category-list li a:hover {
  background-color: rgb(255, 255, 255);
  -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
  color: #0e2207;
  text-shadow: 1px 0 0 rgba(14, 34, 7, 0.5);
}
.sidebar__category-list li a:hover span {
  color: #ffcd1e;
  -webkit-transform: translateY(-50%) scale(1);
  transform: translateY(-50%) scale(1);
}
.sidebar__category-list li a span {
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translateY(-50%) scale(0);
  transform: translateY(-50%) scale(0);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  color: var(--theme-color2);
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 16px;
}
.sidebar__category-list li.active a {
  background-color: rgb(255, 255, 255);
  -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
  color: #0e2207;
  border-radius: 10px;
  text-shadow: 1px 0 0 rgba(14, 34, 7, 0.5);
}
.sidebar__category-list li.active a span {
  -webkit-transform: translateY(-50%) scale(1);
  transform: translateY(-50%) scale(1);
  color: #ffcd1e;
}

.sidebar__tags {
  position: relative;
  display: block;
  color: var(--theme-light-background-text-color);
  background-color: var(--theme-light-background);
  padding: 46px 45px 50px;
  border-radius: 10px;
  overflow: hidden;
  z-index: 1;
}
.sidebar__tags .sidebar__title {
  margin-left: 5px;
  margin-bottom: 25px;
}

.sidebar__tags-list {
  margin-top: -10px;
}
.sidebar__tags-list a {
  font-size: 14px;
  color: #0e2207;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  background: #ffffff;
  display: inline-block;
  padding: 5px 28px 5px;
  margin-left: 5px;
  border-radius: 30px;
}
.sidebar__tags-list a:hover {
  color: var(--text-color-bg-theme-color1);
  background: var(--theme-color1);
}
.sidebar__tags-list a + a {
  margin-left: 5px;
  margin-top: 10px;
}

.sidebar__comments {
  position: relative;
  display: block;
  color: var(--theme-light-background-text-color);
  background-color: var(--theme-light-background);
  padding: 46px 50px 43px;
  border-radius: 10px;
  overflow: hidden;
  z-index: 1;
}
.sidebar__comments .sidebar__title {
  margin-bottom: 25px;
}

.sidebar__comments-list {
  position: relative;
  display: block;
}
.sidebar__comments-list li {
  position: relative;
  display: block;
  padding-left: 65px;
}
.sidebar__comments-list li:hover .sidebar__comments-icon {
  background-color: var(--theme-color2);
  color: var(--text-color-bg-theme-color2);
}
.sidebar__comments-list li + li {
  margin-top: 23px;
}

.sidebar__comments-icon {
  height: 45px;
  width: 45px;
  background-color: var(--theme-color1);
  border-radius: 50%;
  font-size: 15px;
  color: var(--text-color-bg-theme-color1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.sidebar__comments-text-box p {
  font-size: 15px;
  margin: 0;
  line-height: 26px;
  font-weight: 500;
}
.sidebar__comments-text-box p span {
  color: #0e2207;
}
.sidebar__comments-text-box h5 {
  font-size: 15px;
  margin: 0;
  line-height: 26px;
  color: #757873;
  font-weight: 500;
  letter-spacing: 0;
}

.news-categories li {
  position: relative;
  display: block;
  padding: 12px 0px;
  border-bottom: 1px solid #e1e8e4;
}
.news-categories:last-child {
  border-bottom: none;
}
.news-categories a {
  position: relative;
  display: block;
  color: var(--headings-color);
  font-weight: 600;
}
.news-categories a:hover {
  padding-left: 26px;
}
.news-categories a:hover i {
  opacity: 1;
}
.news-categories i {
  position: absolute;
  left: 0px;
  top: 5px;
  font-size: 16px;
  opacity: 0;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.news-categories span {
  float: right;
}





/***

==================================================================
    About Section
==================================================================

***/
.about-section {
  position: relative;
  padding: 60px 0 70px;
  z-index: 1;
}

.about-section:before {
  position: absolute;
  right: 0;
  top: 0;
  height: 826px;
  width: 100%;
  content: "";
}

.about-section .content-column {
  position: relative;
  margin-bottom: 50px;
}

.about-section .content-column .inner-column {
  position: relative;
  padding-left: 70px;
}
.about-section .content-column .inner-column .sec-title {
  margin-bottom: 30px;
}
.about-section .content-column .inner-column .info-box {
  position: relative;
  padding-right: 200px;
  margin-bottom: 40px;
}
.about-section .content-column .inner-column .video-box {
  position: absolute;
  right: 0;
  top: 0;
  padding: 14px;
  border: 1px solid var(--bg-theme-color3);
  border-radius: 5px;
  overflow: hidden;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.about-section .content-column .inner-column .video-box img {
  border-radius: 10px;
}
.about-section .content-column .inner-column .video-box .icon {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 45px;
  width: 45px;
  background-color: var(--bg-theme-color3);
  color: #ffffff;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.about-section .content-column .inner-column .video-box:hover {
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
          box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.about-section .image-column {
  position: relative;
  margin-bottom: 50px;
}

.about-section .about-image-wrapper {
  position: relative;
  z-index: 1;
}
.about-section .about-image-wrapper .bg-shape {
  position: absolute;
  top: -40px;
  left: -70px;
}
.about-section .about-image-wrapper .image-1 {
  position: relative;
  display: inline-block;
  margin-bottom: 0;
}
.about-section .about-image-wrapper .image-1:before {
  position: absolute;
  left: 70px;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #f6f5f0;
  border-radius: 50%;
  content: "";
}
.about-section .about-image-wrapper .image-1:after {
  position: absolute;
  left: 15%;
  top: 15%;
  height: 70%;
  width: 70%;
  border: 3px solid #ffffff;
  border-radius: 50%;
  -webkit-animation: zoom-one 10s infinite linear 2s;
          animation: zoom-one 10s infinite linear 2s;
  content: "";
}
.about-section .about-image-wrapper .image-1 img {
  position: relative;
  height: 550px;
  min-width: 550px;
  width: 550px;
  border-radius: 50%;
}
.about-section .about-image-wrapper .image-2 {
  position: absolute;
  bottom: -60px;
  right: -60px;
  margin-bottom: 0;
}
.about-section .about-image-wrapper .image-2 img {
  max-width: 100%;
  height: auto;
}

/***

==================================================================
    About Section Two
==================================================================

***/
.about-section-two {
  position: relative;
  padding: 120px 0 70px;
  background-color: var(--bg-theme-color1);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 1;
}

.about-section-two .content-column {
  position: relative;
  margin-bottom: 50px;
}
.about-section-two .content-column .inner-column {
  position: relative;
}
.about-section-two .content-column .text {
  font-size: 16px;
  line-height: 30px;
  color: #b3c2c1;
}
.about-section-two .content-column .theme-btn {
  margin-top: 40px;
}

.about-block {
  position: relative;
}
.about-block .inner-box {
  padding-left: 95px;
  padding-right: 10px;
  margin-bottom: 30px;
  min-height: 82px;
}
.about-block .inner-box:hover .icon {
  -webkit-transform: scale(-1) rotate(180deg);
          transform: scale(-1) rotate(180deg);
  background-color: var(--bg-theme-color3);
  color: #fff;
}
.about-block .title {
  font-size: 16px;
  color: #b3c2c1;
  line-height: 24px;
}
.about-block h5 {
  position: relative;
  font-size: 20px;
  color: #ffffff;
  font-weight: 600;
  margin-bottom: 10px;
}
.about-block .icon {
  position: absolute;
  left: 0;
  top: 0;
  height: 80px;
  width: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 36px;
  color: var(--theme-color3);
  border: 4px solid var(--border-theme-color3);
  border-radius: 50%;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.about-section-two .fact-counter-one {
  position: absolute;
  left: -15px;
  top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 135px;
  width: 135px;
  background-color: #fff;
  border-radius: 50%;
  margin-bottom: 40px;
}
.about-section-two .fact-counter-one:before {
  position: absolute;
  right: 22px;
  bottom: 10px;
  height: 10px;
  width: 10px;
  background: #fff;
  content: "";
}

.fact-counter-one .count-box {
  position: relative;
  font-size: 30px;
  color: var(--theme-color1);
  line-height: 1em;
  font-weight: 700;
}

.fact-counter-one .counter-title {
  display: block;
  font-size: 12px;
  line-height: 20px;
  font-weight: 700;
  text-transform: uppercase;
  color: #819291;
}

.about-section-two .image-column {
  position: relative;
  margin-bottom: 50px;
}

.about-section-two .image-box {
  position: relative;
  margin-right: -200px;
  margin-top: -10px;
  padding-left: 60px;
}
.about-section-two .image-box .image {
  position: relative;
  margin-bottom: 0;
}
.about-section-two .image-box .image:before {
  position: absolute;
  top: 130px;
  left: -25px;
  height: 64px;
  width: 64px;
  background-color: var(--bg-theme-color2);
  border-radius: 50%;
  -webkit-animation: zoom-one 3s infinite linear;
          animation: zoom-one 3s infinite linear;
  content: "";
}
.about-section-two .image-box .image img {
  width: 100%;
  -webkit-filter: drop-shadow(0 20px 40px rgba(0, 0, 0, 0.4));
          filter: drop-shadow(0 20px 40px rgba(0, 0, 0, 0.4));
}

@media only screen and (max-width: 1700px){
	.large-container{
		max-width: 1430px !important;
	}

	.banner-section .social-icon-two{
		left: 2%;
	}

	.banner-section .float-text{
		right: -45px;
		right: -3%;
	}
}

@media only screen and (max-width: 1399px){
	.banner-section .content-box h1{
		font-size: 72px;
	}

	.header-style-two .main-menu .navigation > li{
		margin: 0 25px;
	}
}



@media only screen and (max-width: 1199px){
	.faqs-section .faq-column .inner-column{
		padding-left: 0;
	}

	.about-section .about-image-wrapper .image-1:before,
	.faqs-section .image-column .image:before{
		display: none;
	}
	
	.feature-block .inner {
		padding-left: 30px;
	}

	.header-style-two .header-lower .main-menu .navigation>li {
		margin: 0 30px;
	}

	.header-style-two .contact-info-box {
		display: none;
	}

	.about-section-three .sec-title br{
		display:none;
	}

	.feature-list li .inner{
		padding: 15px 20px;
	}

	.about-section-three .content-column .inner-column {
		padding-left: 0;
	}

	.feature-block-two .inner-box {
		padding-right: 110px;
	}

	.why-choose-us .right-column .feature-block-two .inner-box {
		padding-left: 110px;
	}

	.feature-block-two .icon-box {
		right: 10px;
	}

	.why-choose-us .right-column .feature-block-two .icon-box {
		left: 10px;
	}

	.feature-block-two .icon-box .icon {
		height: 80px;
		width: 80px;
		font-size: 52px
	}

	.about-section .about-image-wrapper .image-1 img {
		min-width: auto;
		width: 400px;
		height: 400px;
	}

	.about-section .about-image-wrapper .bg-shape img{
		width: 400px;
	}

	.about-section .content-column .inner-column{
		padding-left: 0;
	}

	.about-section .about-image-wrapper .image-2{
		right:-30px;
	}
}


@media only screen and (min-width: 768px){
	.main-menu .navigation > li > ul,
	.main-menu .navigation > li > ul > li > ul{
		display:block !important;
		visibility:hidden;
		opacity:0;
	}
}


@media only screen and (max-width: 1023px){

	.main-header .logo img,
	.sticky-header .logo img{
		max-height: 40px;
		width: auto;
		max-width: none;
	}

	.main-header .ui-btn.mobile-search-btn,
	.mobile-nav-toggler{
		display: block;
	}

	.main-header .logo{
		min-width: auto;
	}

	.main-header .ui-btn{
		font-size: 24px;
		line-height: 35px;
		margin-left: 20px;
	}

	
	.main-header .main-menu,
	.header-style-one .header-top .top-right .contact-info-box,
	.header-style-one .header-top .top-right .divider,
	.header-style-one .header-top .top-left,
	.header-style-one .header-lower{
		display: none;
	}

	.header-style-one .header-top .top-center{
		justify-content: flex-start;
		min-height: auto;
	}

	.banner-section .content-box h1,
	.main-slider h2{
		font-size: 54px;
	}

	.main-slider .text {
		font-size: 16px;
		color: #ffffff;
	}

	.about-section .content-column .inner-column{
		margin-top: 50px;
		padding-left: 0;
	}

	.banner-box-one{
		min-width: 100%;
	}

	.faqs-section .bg-pattern-left{
		width: 100%;
		right: 0;
		height: 300px;
		margin: 0;
	}

	.about-section-two .image-box{
		margin-right: 0;
	}

	.about-section-two .fact-counter-one{
		left: 0;
	}

	.tp-dottedoverlay {
		display: block;
		opacity: .70;
	}

	.banner-section{
		padding: 80px 0 0;
	}

	.banner-section .content-box .icon-leaf{
		top: 30px;
		right: 50%;
		margin-right: -70px;
	}

	.banner-section .theme-btn{
		padding: 10px 40px;
	}

	.features-section{
		padding: 40px 0 40px;
	}

	.feature-block{
		margin-bottom: 50px;
	}

	.feature-block .inner{
		text-align: center;
		padding-left: 0;
	}

	.feature-block .icon{
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 58px;
		min-height: auto;
		height: 100px;
		margin: 0 auto 15px;
	}

	.feature-block .icon:after{
		border-radius: 50%;
	}

	.about-section-three .image-box{
		justify-content: center;
	}

	.about-section-three .image-box:before{
		left:0;
	}

	.contact-section .image-column .image:before,
	.contact-section .main-bg .bg-pattern{
		display:none;
	}

	.contact-section .main-bg{
		max-height: 70%;
	}

	.contact-section .image-column .image{
		left: 0;
		padding-left: 0;
	}

	.contact-section .form-column .inner-column{
		padding: 60px 50px;
		margin: 0;
	}

	.testimonial-block-two .text{
		font-size: 24px;
		line-height: 1.4em;
	}

	.why-choose-us .image-column{
		order: 0 !important;
		padding-top: 30px;
	}

	.why-choose-us .left-column,
	.why-choose-us .right-column{
		padding-top: 70px;
	}

	.why-choose-us .left-column .inner-column:before, 
	.why-choose-us .right-column .inner-column:before{
		display: none;
	}

	.about-section .content-column .inner-column .info-box{
		padding-right: 320px;
	}

	.about-section .content-column .inner-column .video-box img{
		min-width: 270px;
	}

	.about-section .about-image-wrapper{
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.about-section .about-image-wrapper .bg-shape{
		left: 10%;
	}

	.banner-section .social-icon-two{
		display: none;
	}
}


@media only screen and (max-width: 767px){
	.main-slider h2 {
		font-size: 42px;
	}

	.main-slider .text {
		font-size: 14px;
		line-height: 24px;
	}

	.main-slider .theme-btn{
		line-height: 20px;
		padding: 10px 40px;
		min-width: auto !important;
	}

	.services-section .outer-box{
		padding: 30px 30px;
	}


	.about-section .about-image-wrapper .image-1 img{
		min-width: 100%;
		height: auto;
	}

	.video-section .content-box h2,
	.sec-title h2{
		font-size: 36px;
		line-height: 1.2em;
	}

	.banner-box-three .content h3,
	.banner-box-one .content h3{
		font-size: 36px;
	}

	.banner-box-two,
	.banner-box-three{
		min-width: 100%;
		height: auto;
	}

	.banner-box-two .inner-box,
	.banner-box-three .inner-box{
		min-height: auto;
	}

	.video-section .content-box .with-icons:before,
	.video-section .content-box .with-icons:after{
		transform: scale(.5) !important;
		animation: none;
	}

	.about-section .about-image-wrapper .bg-shape{
		top: -30px;
	}
	
	.about-section .about-image-wrapper .bg-shape{
		left: -20px;
	}

	.testimonial-section-two,
	.testimonial-section{
		padding: 100px 0;
	}

	.about-section-three,
	.about-section-two,
	.faqs-section{
		padding: 100px 0 50px;
	}

	.news-section,
	.main-footer .widgets-section,
	.banners-section,
	.products-section{
		padding: 100px 0 70px;
	}

	.video-section{
		padding: 100px 20px;
	}

	.main-footer .logo{
		margin-top: 0;
	}

	.fact-counter .count-box,
	.fact-counter .count-box .count-text{
		font-size: 42px
	}

	.testimonial-block-two .inner-box{
		padding-left: 0;
		text-align: center
	}

	.testimonial-block-two .thumb{
		position: relative;
		display: inline-block;
		left:0;
		margin-bottom: 20px;
	}

	.testimonial-thumbs{
		position: relative;
		left: 0;
		top:0;
		display: flex;
		justify-content: center;
		max-width: 225px;
		margin-top: 30px;
	}

	.testimonials .testimonial-pagination{
		display:none;
	}

	.why-choose-us .right-column{
		padding-top: 0;
	}

	.feature-block-two .inner-box{
		padding: 0 !important;
		text-align: center !important;
	}

	.feature-block-two .icon-box{
		right: 0 !important;
		left: 0 !important;
		position: relative;
		display: inline-block;
		margin: 0 auto 20px !important;
	}
}

@media only screen and (max-width: 599px){
	.about-section .about-image-wrapper .image-2{
		max-width: 60%;
		right: -15%;
	}

	.about-section .content-column .inner-column .info-box{
		padding-right: 0;
	}

	.about-section .content-column .inner-column .video-box{
		position: relative;
		margin:20px 0 0;
		width: 100%;
		display: inline-block
	}

	.about-section .content-column .inner-column .video-box img{
		width: 100%;
	}

	.banner-box-one .inner-box,
	.banner-box-two .inner-box,
	.banner-box-three .inner-box {
		padding: 70px 30px;
		justify-content: flex-start;
		min-height: auto;
	}

	.banner-box-one .inner-box .image:before{
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		background-color: rgba(0, 0, 0, .4);
		content: "";
		z-index: 1;
	}

	.subscribe-form .text br,
	.video-section .content-box h2 br,
	.video-section .content-box .with-icons:before,
	.video-section .content-box .with-icons:after{
		display: none;
	}

	.about-section-two .image-box{
		padding-left: 0;
	}

	.about-section-two .fact-counter-one{
		top: -20px;
		left:-20px;
		transform: scale(.7);
		animation: none;
	}

	.gallery-widget{
		padding-right: 0;
	}

	.about-section .about-image-wrapper .image-1:after{
		height: 80%;
		width: 80%;
		left: 10%;
		top: 10%;
	}

	.banner-section .content-box .icon-leaf{
		position: relative;	
		display: inline-block;
		left: 0;
		top: 0;
		margin: 0;
		height: 53px;
		width: 68px;
	}

	.banner-section .content-box{
		padding: 70px 0 120px;
	}

	.banner-carousel .owl-dots{
		margin-bottom: 30px;
	}

	.banner-section .content-box .sub-title{
		font-size: 14px;
		line-height: 24px;
	}

	.about-section-three .image-box{
		flex-direction: column
	}

	.about-section-three .image-box .image-2,
	.about-section-three .image-box .image-1{
		max-width: 100%;
		margin: 0 0 30px;
		padding: 0;
	}

	.about-section-three .exp-box:before,
	.about-section-three .exp-box:after{
		display:none;
	}


	.about-section-three .exp-box{
		position: relative;
		left:0;
		width: 100%;
		margin-top: 30px;
		transform: none !important;
	}

	.feature-list{
		flex-direction: column;
	}

	.feature-list li{
		flex: 0 0 100%;
		max-width: 100%;
		margin-bottom: 20px;
	}

	.banner-box-four .inner-box{
		text-align: center
	}

	.contact-section .form-column .inner-column{
		padding: 40px 20px;
	}

	.contact-form .form-group input[type=submit], .contact-form .form-group button{
		padding: 11px 40px;
		width: 100%;
	}

	.accordion-box .block .acc-btn{
		padding-left: 25px;
		font-size: 18px;
		line-height: 1.4em;
	}

	.accordion-box .block .acc-content .content{
		padding-left: 25px;
	}
	
	.accordion-box .block .acc-content .content .text{
		font-size: 16px;
		line-height: 24px;
	}

	.banner-carousel .owl-nav{
		display: none;
	}

	.banner-box-four .image:before{
		opacity: .5;
	}
}

@media only screen and (max-width: 424px){
	.main-slider h2 {
		font-size: 36px;
	}

	.testimonial-block-two .text{
		font-size: 18px;
	}

	.news-block .lower-content{
		padding: 30px 20px 20px;
	}
}