/* You can add global styles to this file, and also import other style files */
.btn-primary:disabled {
  cursor: not-allowed !important;
}

.btn:hover {
  color: black !important;
}



